var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Flex, SimpleGrid, Text, Box, Badge, } from '@chakra-ui/layout';
import { RiHome6Fill, RiShoppingBag2Fill, RiFileList2Fill, } from 'react-icons/ri';
import { RiWallet3Fill } from 'react-icons/ri';
import { useRouter, useNotifications } from 'src/core/hooks';
import { VirtualAccountModal } from 'src/merchant';
import { analytics } from 'src/core/utils';
import { useCreditLimit, useOrderConfig } from '@obtainly/hooks';
import { distributorsAtom, useAtom, userAtom } from '../store';
export var Navigation = function () {
    // Click source is usually the name of the component
    var CLICK_SOURCE = 'Navigation';
    var router = useRouter();
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = React.useState(false), virtualAccount = _a[0], setVirtualAccount = _a[1];
    var _b = useNotifications(), payments = _b.payments, funding = _b.funding;
    var creditLimit = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), user === null || user === void 0 ? void 0 : user._id).creditLimit;
    var isOrderActivated = useOrderConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id, user === null || user === void 0 ? void 0 : user._id).isOrderActivated;
    var navColumns = useMemo(function () {
        var count = 2;
        if (creditLimit) {
            count += 1;
        }
        if (isOrderActivated) {
            count += 1;
        }
        return count;
    }, [creditLimit, isOrderActivated]);
    return (_jsxs(_Fragment, { children: [_jsxs(SimpleGrid, __assign({ pb: 1, bg: "white", height: "70px", position: "fixed", bottom: 0, transition: "all 300ms", width: "100%", boxShadow: "1px -1px 10px rgba(23, 28, 52, 0.06)", borderRadius: "10px 10px 0 0", columns: navColumns, zIndex: 2 }, { children: [_jsx(NavigationItem, { icon: RiHome6Fill, label: "Home", isActive: location.pathname === '/', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Home',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('dashboard');
                        } }, void 0), isOrderActivated ? (_jsx(NavigationItem, { icon: RiShoppingBag2Fill, label: "Place Order", isActive: location.pathname === '/orders/new', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Place Order',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('order', { id: 'new' });
                        } }, void 0)) : null, creditLimit ? (_jsx(NavigationItem, { icon: RiWallet3Fill, label: "Credit", isActive: location.pathname === '/funding-requests', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Credit',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('funding-requests');
                        }, counter: funding === null || funding === void 0 ? void 0 : funding.count }, void 0)) : null, _jsx(NavigationItem, { icon: RiFileList2Fill, label: "Payments", isActive: location.pathname === '/payments', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Payments',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('payments');
                        }, counter: payments === null || payments === void 0 ? void 0 : payments.count }, void 0)] }), void 0), _jsx(Box, { marginTop: "100px" }, void 0), _jsx(VirtualAccountModal, { isOpen: virtualAccount, onClose: function () { return setVirtualAccount(false); } }, void 0)] }, void 0));
};
var NavigationItem = function (props) {
    var icon = props.icon, label = props.label, isActive = props.isActive, onClick = props.onClick;
    var Icon = icon;
    return (_jsxs(Flex, __assign({ cursor: "pointer", opacity: onClick ? 1 : 0.5, color: isActive ? 'primary.500' : 'gray.700', flexDir: "column", justifyContent: "center", alignItems: "center", position: "relative", onClick: onClick }, { children: [!!props.counter && (_jsx(Badge, __assign({ position: "absolute", fontSize: "10px", borderRadius: "10px", color: "white", bg: "critical.500", top: "4px", right: "calc(50% - 28px)" }, { children: props.counter }), void 0)), _jsx(Box, __assign({ bg: isActive ? 'primary.50' : 'transparent', borderRadius: 99999 }, { children: _jsx(Icon, { size: 20 }, void 0) }), void 0), _jsx(Text, __assign({ mt: 1, fontSize: "sm", fontWeight: "medium" }, { children: label }), void 0)] }), void 0));
};
