import { Notifications } from './pages/Notifications';
export var notificationPermissions = [];
export var notificationRoutes = [
    {
        id: 'notifications',
        path: '/notifications',
        component: Notifications,
        requiresAuth: true,
        exact: true,
    },
];
