var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup } from '@obtainly/ui/web-shared/FormField';
import { useToast } from 'src/core/hooks';
import { analytics, Event } from 'src/core/utils';
import { useQueryParams } from '@obtainly/hooks';
import { useMutateModel } from '@obtainly/http';
var formSchema = Yup.object({
    phone: Yup.string().required('Customer phone number is required.'),
});
export var PasswordResetForm = function (_a) {
    var onSuccess = _a.onSuccess;
    var toast = useToast().toast;
    var phone = useQueryParams().phone;
    var initialFormValues = {
        phone: phone || '',
    };
    var requestCode = useMutateModel('merchant.forgot_auth');
    var onSubmit = function (values, _a) {
        var resetForm = _a.resetForm, setSubmitting = _a.setSubmitting;
        requestCode.mutate({
            phone: values.phone,
            expires_in_minutes: 120,
            send_notification_too: true,
            commit: true,
        }, {
            onSuccess: function () {
                analytics.track(Event.PasswordReset);
                toast({ status: 'success', description: 'Request sent' });
                resetForm();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
            onSettled: function () { return setSubmitting(false); },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialFormValues, validationSchema: formSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(FormGroup, __assign({ label: "Phone" }, { children: _jsx(FormikField, { autoComplete: "off", name: "phone", type: "tel", pattern: "[0-9]*", placeholder: "Enter your phone number" }, void 0) }), void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: formik.isSubmitting || !formik.isValid, isLoading: formik.isSubmitting, isFullWidth: true }, { children: "Reset password" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};
