var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMerchantDocumentState } from '@obtainly/hooks';
import { useEffect } from 'react';
import { profileAtom, useAtom, userAtom } from '../store';
export function useProfileCompletion() {
    var merchant = useAtom(userAtom)[0];
    var _a = useAtom(profileAtom), setProfile = _a[1];
    var documentsState = useMerchantDocumentState(merchant === null || merchant === void 0 ? void 0 : merchant._id);
    useEffect(function () {
        setProfile(function (state) { return (__assign(__assign(__assign({}, state), documentsState), { unverified: Array.from(documentsState.unverified), verified: Array.from(documentsState.verified), unset: Array.from(documentsState.unset) })); });
    }, [documentsState]); // eslint-disable-line
    return documentsState;
}
