var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex, Grid, VStack } from '@chakra-ui/layout';
import { useParams } from 'react-router';
import { ContentLoader, Layout } from 'src/core/components';
import { usePermission } from 'src/core/hooks';
import { useQueryModel } from '@obtainly/http';
import { ComplaintMetaTag } from '@obtainly/models';
import ComplaintActionButton from '../components/ComplaintActionButton';
import { ComplaintDetails } from '../components/ComplaintDetails';
import { ComplaintMetaDetails } from '../components/ComplaintMetaDetails';
export var Complaint = function () {
    var _a;
    var userCan = usePermission().userCan;
    var complaintId = useParams().id;
    var _b = useQueryModel('complaint.read', { _id: complaintId }), complaints = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    var complaint = React.useMemo(function () {
        return (complaints === null || complaints === void 0 ? void 0 : complaints[0]) || null;
    }, [complaints]);
    return (_jsx(Layout, __assign({ headerProps: { title: 'Complaints' } }, { children: !complaint ? (_jsx(ContentLoader, { isLoading: isLoading, errorSummary: "Complaint not found" }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Flex, { children: userCan('update_complaint') && (_jsx(ComplaintActionButton, { complaint: complaint, onUpdate: refetch }, void 0)) }, void 0), _jsx(Grid, __assign({ templateColumns: ['1fr', '1fr', '1fr'], gridGap: 5 }, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch", overflowX: "auto" }, { children: [_jsx(ComplaintDetails, { complaint: complaint }, void 0), _jsx(ComplaintMetaDetails, { complaintTag: complaint.tag, complaintMetas: (_a = complaint === null || complaint === void 0 ? void 0 : complaint.complaint_metas) === null || _a === void 0 ? void 0 : _a.filter(function (meta) {
                                    return meta.tag === ComplaintMetaTag.Product;
                                }) }, void 0)] }), void 0) }), void 0)] }, void 0)) }), void 0));
};
