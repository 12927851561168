var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Button } from '@chakra-ui/button';
import { FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, Layout } from 'src/core/components';
import { useNotifications, useRouter } from 'src/core/hooks';
import { useAtom, userAtom, distributorsAtom } from 'src/core/store';
import { ContentLoader } from '@obtainly/ui/web-shared';
import { FundingRequestsList } from '../components';
export var FundingRequests = function () {
    var router = useRouter('funding-requests');
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useQueryModel('funding_request.read_by_merchant_distributor', {
        merchant_id: user === null || user === void 0 ? void 0 : user._id,
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }, { enabled: !!(user === null || user === void 0 ? void 0 : user._id) || !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), fundingRequests = _a.data, isLoading = _a.isLoading;
    var _b = useNotifications(), fundingNotification = _b.funding, readNotifications = _b.readNotifications;
    useEffect(function () {
        return function () { return readNotifications('funding'); };
    }, []); // eslint-disable-line
    var filteredFundings = useMemo(function () {
        if (!(fundingRequests === null || fundingRequests === void 0 ? void 0 : fundingRequests.length)) {
            return [];
        }
        if (!(fundingNotification === null || fundingNotification === void 0 ? void 0 : fundingNotification.count)) {
            return fundingRequests;
        }
        var fundingRefs = fundingNotification === null || fundingNotification === void 0 ? void 0 : fundingNotification.data.map(function (item) { return item.meta_obj.ref; });
        return fundingRequests.map(function (funding) {
            if (fundingRefs.includes(funding.ref)) {
                return __assign(__assign({}, funding), { is_new: true });
            }
            return funding;
        });
    }, [fundingNotification, fundingRequests]);
    return (_jsxs(Layout, __assign({ headerProps: { title: 'Credits' } }, { children: [_jsx(Button, __assign({ mt: 3, size: "sm", variant: "solid", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return router.go('new-funding-request'); }, isFullWidth: true }, { children: "Get Credit" }), void 0), _jsx(Card, __assign({ mt: 4 }, { children: _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(filteredFundings === null || filteredFundings === void 0 ? void 0 : filteredFundings.length), errorMessage: "No credits found" }, void 0), !!(filteredFundings === null || filteredFundings === void 0 ? void 0 : filteredFundings.length) && (_jsx(FundingRequestsList, { fundingRequests: filteredFundings.sort(function (a, b) { return Number(b.created) - Number(a.created); }) }, void 0))] }), void 0) }), void 0)] }), void 0));
};
