var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/layout';
import React from 'react';
import { ContentLoader, Layout } from 'src/core/components';
import { useQueryModel } from '@obtainly/http';
import { useAtom, distributorsAtom } from 'src/core/store';
import { PromotionCard } from '../components/PromotionCard';
export var Promotions = function () {
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useQueryModel('promotion.read_by_distributor_active_expanded', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), promotions = _a.data, isLoading = _a.isLoading;
    return (_jsx(Layout, __assign({ headerProps: { title: 'Active Promotions' } }, { children: _jsxs(VStack, __assign({ spacing: 3, alignItems: "stretch" }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(promotions === null || promotions === void 0 ? void 0 : promotions.length), errorSummary: "No promotions found" }, void 0), !isLoading && (promotions === null || promotions === void 0 ? void 0 : promotions.length)
                    ? promotions.map(function (promotion) { return (_jsx(PromotionCard, { promotion: promotion }, promotion._id)); })
                    : null] }), void 0) }), void 0));
};
