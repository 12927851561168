var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect } from 'react';
import { installationAtom, useAtom } from '../store';
export function useAppInstallation() {
    var _this = this;
    var _a = useAtom(installationAtom), installation = _a[0], setInstallation = _a[1];
    var installApp = function () { return __awaiter(_this, void 0, void 0, function () {
        var deferredPrompt_1, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ((_a = installation.installEvent) === null || _a === void 0 ? void 0 : _a.prompt())];
                case 1:
                    deferredPrompt_1 = _b.sent();
                    setInstallation(function (state) { return (__assign(__assign({}, state), { isInstalled: deferredPrompt_1.outcome === 'accepted' })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    console.error('install error', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateApp = function () {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(function (reg) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (reg) {
                            // await reg.update()
                            reg.unregister().then(function () {
                                window.location.reload();
                            });
                        }
                        else {
                            window.location.reload();
                        }
                        return [2 /*return*/];
                    });
                });
            });
        }
        else {
            console.warn('Service worker not supported.');
            window.location.reload();
        }
    };
    // TO-DO
    // detect app is already installed
    // prompt to open app
    // --- implementation ---
    // set related apps in manifest.json
    // get installed related apps and do comparison
    // window.navigator.getInstalledRelatedApps()
    // lastly, open app
    // window.open(window.location.href, '_blank')
    useEffect(function () {
        function startInstaller() {
            return __awaiter(this, void 0, void 0, function () {
                var registration, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!window.matchMedia('(display-mode: standalone)').matches) return [3 /*break*/, 1];
                            // app was launched in standalone mode (pwa-mode)
                            // don't show prompt
                            setInstallation(function (state) { return (__assign(__assign({}, state), { isInstalled: true })); });
                            return [3 /*break*/, 7];
                        case 1:
                            if (!('serviceWorker' in navigator)) return [3 /*break*/, 7];
                            window.addEventListener('beforeinstallprompt', function (event) {
                                console.info('before install', event);
                                setInstallation(function (state) { return (__assign(__assign({}, state), { installEvent: event })); });
                            });
                            window.addEventListener('appinstalled', function () {
                                console.info('app installed');
                                // app was installed, no need to show prompt
                                setInstallation(function (state) { return (__assign(__assign({}, state), { isInstalled: true })); });
                            });
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 6, , 7]);
                            return [4 /*yield*/, navigator.serviceWorker.getRegistration()];
                        case 3:
                            registration = _a.sent();
                            if (!registration) return [3 /*break*/, 5];
                            return [4 /*yield*/, registration.update()];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            error_2 = _a.sent();
                            console.error("Registration failed with ".concat(error_2));
                            setInstallation(function (state) { return (__assign(__assign({}, state), { isReady: true })); });
                            return [3 /*break*/, 7];
                        case 7:
                            setInstallation(function (state) { return (__assign(__assign({}, state), { isReady: true })); });
                            return [2 /*return*/];
                    }
                });
            });
        }
        startInstaller();
        return function () {
            window.removeEventListener('beforeinstallprompt', function () { return null; });
            window.removeEventListener('appinstalled', function () { return null; });
        };
    }, [setInstallation]);
    return {
        installation: installation,
        setInstallation: setInstallation,
        installApp: installApp,
        updateApp: updateApp,
    };
}
