import { useQueryModels } from '@obtainly/http';
import { useMemo } from 'react';
export function useCreditLimit(distributorId, merchantId) {
    var _a = useQueryModels([
        {
            requestString: 'merchant_tag.read_credit_limit',
            payload: {
                merchant_id: merchantId,
                distributor_id: distributorId,
            },
            options: {
                enabled: !!merchantId && !!distributorId,
            },
        },
        {
            requestString: 'merchant_tag.read_available_credit',
            payload: {
                merchant_id: merchantId,
                distributor_id: distributorId,
            },
            options: {
                enabled: !!merchantId && !!distributorId,
            },
        },
        {
            requestString: 'merchant_tag.read_used_credit',
            payload: {
                merchant_id: merchantId,
                distributor_id: distributorId,
                use_approved: false,
            },
            options: {
                enabled: !!merchantId && !!distributorId,
            },
        },
    ], {
        refetchOnWindowFocus: true,
    }), creditLimitQuery = _a[0], availableCreditQuery = _a[1], usedCreditQuery = _a[2];
    var _b = creditLimitQuery, creditLimitData = _b.data, isLoading = _b.isLoading;
    var availableCreditData = availableCreditQuery.data;
    var usedCreditData = usedCreditQuery.data;
    var creditLimit = useMemo(function () {
        return (creditLimitData === null || creditLimitData === void 0 ? void 0 : creditLimitData.credit_limit) || null;
    }, [creditLimitData]);
    var availableCreditLimit = useMemo(function () {
        var value = availableCreditData === null || availableCreditData === void 0 ? void 0 : availableCreditData.available_credit;
        return value && value > 0 ? value : null;
    }, [availableCreditData]);
    var usedCredit = useMemo(function () {
        if (creditLimit !== null && !!usedCreditData) {
            // return usedCreditData?.used_credit || null
            return (usedCreditData === null || usedCreditData === void 0 ? void 0 : usedCreditData.bnpl_balance) || null;
        }
        return null;
    }, [creditLimit, usedCreditData]);
    return {
        isLoading: isLoading,
        creditLimit: creditLimit,
        availableCreditLimit: availableCreditLimit,
        usedCredit: usedCredit,
    };
}
