var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PasswordForm } from '../components';
import { Card, CardBody } from '@obtainly/ui/web-shared';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Layout, Logo } from 'src/core/components';
export var UpdatePassword = function () {
    return (_jsxs(Layout, __assign({ bg: "gray.50", py: "40px", hideNavigation: true }, { children: [_jsx(Flex, __assign({ justifyContent: "center" }, { children: _jsx(Logo, {}, void 0) }), void 0), _jsxs(Box, __assign({ mt: "38px", mb: "20px" }, { children: [_jsx(Text, __assign({ fontSize: "larger", fontWeight: "semibold", color: "primary.600" }, { children: "Before you begin" }), void 0), _jsx(Text, __assign({ mt: "4px", fontSize: "16px", fontWeight: "medium", color: "gray.600" }, { children: "Let's make your account more secured. Enter a new password only you can remember" }), void 0)] }), void 0), _jsx(Card, { children: _jsx(CardBody, { children: _jsx(PasswordForm, { updateUserSession: true }, void 0) }, void 0) }, void 0)] }), void 0));
};
