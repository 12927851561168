var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useRef } from 'react';
import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, GridItem, SimpleGrid, Text, useBreakpointValue, VisuallyHiddenInput, } from '@chakra-ui/react';
import * as Yup from 'yup';
import { FundingRequestStatus, FundingRequestType, } from '@obtainly/models';
import { Card, CardBody, CardHeader, FormGroup, FormikField, } from 'src/core/components';
import { usePermission } from 'src/core/hooks';
import { useAtom, userAtom, distributorsAtom, fundingAtom, } from 'src/core/store';
import { useCreditLimit } from '@obtainly/hooks';
import { ContentLoader, CreditInfoBox } from '@obtainly/ui/web-shared';
import { Form, Formik } from 'formik';
import { formatCurrency, getFundingRequestPayback, MIN_CREDIT_AMOUNT, } from '@obtainly/utils';
import { RadioCardGroup } from '@obtainly/ui/web-shared/FormField';
import { useQueryModel } from '@obtainly/http';
export var FundingRequestForm = function () {
    var _a;
    var _b, _c, _d;
    var userCan = usePermission().userCan;
    var user = useAtom(userAtom)[0];
    var _e = useAtom(fundingAtom), _f = _e[0], amount = _f.amount, duration = _f.duration, type = _f.type, setFundingAtom = _e[1];
    var distributor = useAtom(distributorsAtom)[0].active;
    var rates = useQueryModel('funding_request.rates', {
        merchant_id: user === null || user === void 0 ? void 0 : user._id,
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }, { enabled: !!(user === null || user === void 0 ? void 0 : user._id) || !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }).data;
    var submitButtonRef = useRef(null);
    var formNavigationProps = useBreakpointValue({
        base: {
            position: 'fixed',
            left: '0px',
            right: '0px',
            bottom: '62px',
            bgColor: 'white',
            padding: '10px 15px',
            borderTop: 'gray.100 1px solid',
            borderBottom: 'gray.100 1px solid',
            align: 'center',
            justify: 'space-between',
            zIndex: 'sticky',
        },
        md: {
            position: 'initial',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            bgColor: 'transparent',
            padding: '0px',
            borderTop: 'none',
            borderBottom: 'none',
            align: 'center',
            justify: 'space-between',
            zIndex: undefined,
        },
    });
    var typeOptions = [
        {
            label: 'Fixed',
            value: FundingRequestType.Fixed,
            description: "Pay back credit in ".concat(duration, " days"),
        },
        {
            label: 'Flexible',
            value: FundingRequestType.Flexible,
            description: "Pay back anytime within the next ".concat(duration, " days"),
        },
    ];
    var paybackExplanation = (_a = {},
        _a[FundingRequestType.Fixed] = "You pay back the full amount of credit provided in ".concat(duration, " days. If credit is paid back before ").concat(duration, " days, the full monthly interest would still be charged."),
        _a[FundingRequestType.Flexible] = "You pay back your credit anytime within ".concat(duration, " days and get charged only interest up until the day you pay back. There is a flat service charge of ").concat((_b = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _b === void 0 ? void 0 : _b.service_charge, "% and a monthly flexible interest rate of ").concat(Number((_c = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _c === void 0 ? void 0 : _c.interest_rate_for_duration) -
            Number((_d = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _d === void 0 ? void 0 : _d.service_charge), "% per credit request."),
        _a);
    // Setup merchant
    var _g = React.useState(), merchant = _g[0], setMerchant = _g[1];
    React.useEffect(function () {
        user && setMerchant(user);
    }, [user]);
    // Credit limit
    var _h = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), merchant === null || merchant === void 0 ? void 0 : merchant._id), creditLimit = _h.creditLimit, availableCreditLimit = _h.availableCreditLimit, isLoading = _h.isLoading;
    // Create order stuff
    var handleCreate = function (values) {
        setFundingAtom(function (old) {
            var _a, _b;
            return (__assign(__assign({}, old), { step: 2, amount: values.amount, type: values.type, interestRate: values.type === FundingRequestType.Fixed
                    ? (_a = rates === null || rates === void 0 ? void 0 : rates.fixed) === null || _a === void 0 ? void 0 : _a.interest_rate_for_duration
                    : (_b = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _b === void 0 ? void 0 : _b.interest_rate_for_duration }));
        });
    };
    var formSchema = useMemo(function () {
        return Yup.object({
            amount: Yup.number()
                .required('Amount is required.')
                .min(MIN_CREDIT_AMOUNT, "Amount cannot be less than ".concat(formatCurrency(MIN_CREDIT_AMOUNT)))
                .max(availableCreditLimit || 0, "Amount cannot be more than credit limit (".concat(formatCurrency(availableCreditLimit || 0), ")")),
            type: Yup.string().required('Choose a pay back plan.'),
            status: Yup.string().required('Status is required.'),
            merchant_id: Yup.string().required('Merchant id is required.'),
            distributor_id: Yup.string().required('Distributor id is required.'),
        });
    }, [availableCreditLimit]);
    var initialValues = {
        amount: amount || 0,
        type: type || '',
        status: FundingRequestStatus.Pending,
        merchant_id: (merchant === null || merchant === void 0 ? void 0 : merchant._id) || '',
        distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
    };
    return (_jsxs(_Fragment, { children: [!!(user === null || user === void 0 ? void 0 : user._id) && !!distributor && (_jsx(CreditInfoBox, { merchantId: user === null || user === void 0 ? void 0 : user._id, distributor: distributor }, void 0)), _jsxs(Card, __assign({ as: "section" }, { children: [_jsx(CardHeader, { heading: "Credit Form", display: "none", border: 0 }, void 0), _jsxs(CardBody, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !creditLimit, errorMessage: "Credit limit not set or unavailable" }, void 0), !isLoading && !!creditLimit && (_jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: handleCreate, enableReinitialize: true }, { children: function (_a) {
                                    var _b, _c, _d, _e, _f, _g;
                                    var formik = __rest(_a, []);
                                    return (_jsx(Form, __assign({ onSubmit: formik.handleSubmit }, { children: _jsxs(SimpleGrid, __assign({ columns: 1, spacing: "20px" }, { children: [_jsxs(GridItem, { children: [_jsx(FormGroup, __assign({ label: "Amount" }, { children: _jsx(FormikField, { type: "number", size: "sm", name: "amount" }, void 0) }), void 0), !!paybackExplanation[formik.values.type] &&
                                                            ((_b = formik === null || formik === void 0 ? void 0 : formik.values) === null || _b === void 0 ? void 0 : _b.amount) > 0 && (_jsxs(Alert, __assign({ status: "warning", borderRadius: "6px", alignItems: "start" }, { children: [_jsx(AlertIcon, {}, void 0), _jsx(AlertDescription, { children: _jsxs(Text, { children: ["Collect", ' ', _jsx(Text, __assign({ as: "span", fontWeight: "semibold" }, { children: formatCurrency((_c = formik === null || formik === void 0 ? void 0 : formik.values) === null || _c === void 0 ? void 0 : _c.amount, {
                                                                                    showCurrency: true,
                                                                                    decimals: 0,
                                                                                }) }), void 0), ' ', "to payback", ' ', _jsxs(Text, __assign({ as: "span", fontWeight: "semibold" }, { children: [formatCurrency(getFundingRequestPayback(formik.values.amount, formik.values.type ===
                                                                                        FundingRequestType.Fixed
                                                                                        ? (_d = rates === null || rates === void 0 ? void 0 : rates.fixed) === null || _d === void 0 ? void 0 : _d.interest_rate_for_duration
                                                                                        : (_e = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _e === void 0 ? void 0 : _e.interest_rate_for_duration).payback, {
                                                                                        showCurrency: true,
                                                                                        decimals: 0,
                                                                                    }), ' '] }), void 0), "in ", duration, " days at", ' ', formik.values.type === FundingRequestType.Fixed
                                                                                ? (_f = rates === null || rates === void 0 ? void 0 : rates.fixed) === null || _f === void 0 ? void 0 : _f.interest_rate_for_duration
                                                                                : (_g = rates === null || rates === void 0 ? void 0 : rates.flexible) === null || _g === void 0 ? void 0 : _g.interest_rate_for_duration, "% interest rate."] }, void 0) }, void 0)] }), void 0))] }, void 0), _jsxs(GridItem, { children: [_jsx(FormGroup, __assign({ label: "Credit Pay Back Plan", helperText: "Select a credit pay back plan" }, { children: _jsx(RadioCardGroup, { value: formik.values.type, onChange: function (value) {
                                                                    formik.setFieldValue('type', value);
                                                                }, options: typeOptions, errorMessage: formik.errors.type }, void 0) }), void 0), !!paybackExplanation[formik.values.type] && (_jsxs(Alert, __assign({ borderRadius: "6px", alignItems: "start" }, { children: [_jsx(AlertIcon, {}, void 0), _jsxs(AlertDescription, { children: [_jsxs(Text, __assign({ fontWeight: "semibold", textTransform: "capitalize" }, { children: [formik.values.type, " pay back plan"] }), void 0), _jsx(Text, { children: paybackExplanation[formik.values.type] }, void 0)] }, void 0)] }), void 0))] }, void 0), _jsx(GridItem, { children: _jsx(VisuallyHiddenInput, { type: "submit", ref: submitButtonRef }, void 0) }, void 0)] }), void 0) }), void 0));
                                } }), void 0))] }, void 0)] }), void 0), _jsxs(Flex, __assign({}, formNavigationProps, { children: [_jsx(Box, {}, void 0), _jsx(Button, __assign({ colorScheme: "primary", onClick: function () { var _a; return (_a = submitButtonRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, size: "sm", isLoading: isLoading, isDisabled: isLoading || !merchant || !userCan('begin_funding_request') }, { children: "Continue" }), void 0)] }), void 0)] }, void 0));
};
