var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Grid, GridItem } from '@chakra-ui/layout';
import { useMutateModel } from '@obtainly/http';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormGroup, FormikField } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { analytics, Event } from 'src/core/utils';
var profileFormSchema = Yup.object({
    business_name: Yup.string()
        .required('Merchant business name is required.')
        .min(3, 'Merchant business name must be at least 3 characters.'),
    firstname: Yup.string()
        .required('Merchant first name is required.')
        .min(3, 'Merchant first name must be at least 3 characters.'),
    lastname: Yup.string()
        .required('Merchant last name is required.')
        .min(3, 'Merchant last name must be at least 3 characters.'),
    email: Yup.string()
        .required('Merchant email is required.')
        .email('Merchant email must be valid.'),
    address: Yup.string().required('Merchant address is required.'),
});
export var ProfileForm = function () {
    var toast = useToast().toast;
    var _a = useAtom(userAtom), user = _a[0], setUser = _a[1];
    var doUpdate = useMutateModel('merchant.update');
    var initialValues = {
        _id: user === null || user === void 0 ? void 0 : user._id,
        user_id: user === null || user === void 0 ? void 0 : user.user_id,
        business_name: user === null || user === void 0 ? void 0 : user.business_name,
        firstname: user === null || user === void 0 ? void 0 : user.firstname,
        lastname: user === null || user === void 0 ? void 0 : user.lastname,
        phone: user === null || user === void 0 ? void 0 : user.phone,
        email: user === null || user === void 0 ? void 0 : user.email,
        address: user === null || user === void 0 ? void 0 : user.address,
        location_id: user === null || user === void 0 ? void 0 : user.location_id,
        store_type_id: user === null || user === void 0 ? void 0 : user.store_type_id,
        status: user === null || user === void 0 ? void 0 : user.status,
    };
    var onSubmit = function (values) {
        doUpdate.mutate(values, {
            onSuccess: function () {
                analytics.track(Event.ProfileUpdate);
                toast({ status: 'success', description: 'Profile saved' });
                setUser(function (state) {
                    return __assign(__assign({}, state), { firstname: values.firstname, lastname: values.lastname, address: values.address });
                });
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: profileFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(Grid, __assign({ gridGap: "10px", templateColumns: ['1fr', '1fr 1fr'] }, { children: [_jsx(FormGroup, __assign({ label: "Phone number" }, { children: _jsx(FormikField, { size: "sm", name: "phone", placeholder: "Your phone number", isDisabled: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Business name" }, { children: _jsx(FormikField, { size: "sm", name: "business_name", placeholder: "Your business name", isDisabled: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { size: "sm", name: "firstname", placeholder: "Your first name", isDisabled: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name" }, { children: _jsx(FormikField, { size: "sm", name: "lastname", placeholder: "Your last name", isDisabled: true }, void 0) }), void 0), _jsx(GridItem, __assign({ colSpan: [1, 2] }, { children: _jsx(FormGroup, __assign({ label: "Address" }, { children: _jsx(FormikField, { as: "textarea", name: "address", placeholder: "Your business address" }, void 0) }), void 0) }), void 0)] }), void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: doUpdate.isLoading || !formik.dirty || !formik.isValid, isLoading: doUpdate.isLoading }, { children: "Save" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};
