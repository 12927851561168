export var Role;
(function (Role) {
    Role["SupliasAdmin"] = "administrator.administrator";
    Role["SupliasStaff"] = "administrator.staff";
    Role["DistributorStaff"] = "distributor.staff";
    Role["Merchant"] = "merchant.merchant";
    Role["MerchantOfficer"] = "merchant.officer";
    Role["SalesOfficer"] = "sales-officer";
})(Role || (Role = {}));
export var everyone = [
    Role.DistributorStaff,
    Role.Merchant,
    Role.SalesOfficer,
];
