var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertIcon, AlertTitle, Box, Button, Flex, Text, } from '@chakra-ui/react';
import { RiUploadCloudFill, RiFileList2Fill } from 'react-icons/ri';
import { MdVerified } from 'react-icons/md';
import { useRouter } from 'src/core/hooks';
import { distributorsAtom, profileAtom, useAtom, userAtom, } from 'src/core/store';
import { useCreditLimit } from '@obtainly/hooks';
var ProfileStat = function (props) {
    var Icon = props.icon, description = props.description, color = props.color;
    return (_jsxs(Flex, __assign({ align: "center", gap: "3px", color: color }, { children: [_jsx(Box, { children: _jsx(Icon, {}, void 0) }, void 0), _jsx(Text, { children: description }, void 0)] }), void 0));
};
export var MerchantProfileCTA = function () {
    var router = useRouter();
    var profileState = useAtom(profileAtom)[0];
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var creditLimit = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), user === null || user === void 0 ? void 0 : user._id).creditLimit;
    var isLoading = profileState.isLoading, isComplete = profileState.isComplete, unsetCount = profileState.unsetCount, verifiedCount = profileState.verifiedCount, unverifiedCount = profileState.unverifiedCount, requiredDocumentCount = profileState.requiredDocumentCount;
    var verifying = unsetCount === 0 &&
        verifiedCount + unverifiedCount === requiredDocumentCount;
    return !isLoading && !isComplete && !!creditLimit ? (_jsx(Box, __assign({ mt: "15px", px: "15px" }, { children: _jsxs(Alert, __assign({ mb: "20px", p: "20px", status: "info", bg: "#ECF4FF", borderRadius: "lg", alignItems: "flex-start", flexDir: "column", boxShadow: "0px 4px 0px #D1E5FF" }, { children: [_jsx(AlertIcon, { as: RiFileList2Fill, color: "#016DFF", width: "36px", height: "36px" }, void 0), _jsxs(Box, __assign({ mt: "10px" }, { children: [_jsx(AlertTitle, __assign({ fontSize: "2xl", fontFamily: "archivo", fontWeight: "semibold", color: "primary.700" }, { children: verifying ? 'Profile verification' : 'Complete your profile' }), void 0), verifying ? (_jsxs(_Fragment, { children: [_jsx(Text, __assign({ mt: "4px" }, { children: "We are verifying the documents you submitted. This would only take a short while." }), void 0), _jsx(Box, __assign({ mt: "6px" }, { children: _jsx(ProfileStat, { description: "".concat(verifiedCount, " of ").concat(requiredDocumentCount - unsetCount, " verified"), icon: MdVerified, color: "success.600" }, void 0) }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Text, __assign({ mt: "4px" }, { children: "You're almost there! Complete your business profile to start receiving funds." }), void 0), _jsx(Box, __assign({ mt: "6px" }, { children: _jsx(ProfileStat, { description: "".concat(requiredDocumentCount - unsetCount, " of\n                    ").concat(requiredDocumentCount, " uploaded"), icon: RiUploadCloudFill, color: "warning.600" }, void 0) }), void 0), _jsx(Flex, __assign({ align: "center", gridGap: "6px", mt: "10px" }, { children: _jsx(Button, __assign({ size: "sm", colorScheme: "primary", onClick: function () { return router.go('/profile'); } }, { children: "Complete Profile" }), void 0) }), void 0)] }, void 0))] }), void 0)] }), void 0) }), void 0)) : null;
};
