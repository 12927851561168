var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Container } from '@chakra-ui/layout';
import { VStack } from '@chakra-ui/react';
import { useNotifications, useRouter } from 'src/core/hooks';
import { ContentLoader } from 'src/core/components';
import { useAtom, distributorsAtom, userAtom } from 'src/core/store';
import { analytics } from 'src/core/utils';
import { DashboardMenuCard } from './DashboardMenuCard';
import { useCreditLimit, useOrderConfig } from '@obtainly/hooks';
export var DashboardMenu = function (props) {
    var CLICK_SOURCE = 'DashboardMenu';
    var loadingDashboard = props.isLoading;
    var router = useRouter();
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useNotifications(), payments = _a.payments, orders = _a.orders, funding = _a.funding;
    var creditLimit = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), user === null || user === void 0 ? void 0 : user._id).creditLimit;
    var _b = useOrderConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id, user === null || user === void 0 ? void 0 : user._id), loadingOrderConfig = _b.isLoading, isOrderActivated = _b.isOrderActivated;
    var isLoading = useMemo(function () {
        return loadingDashboard || loadingOrderConfig;
    }, [loadingDashboard, loadingOrderConfig]);
    return (_jsxs(Container, __assign({ maxW: "container.lg" }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsxs(VStack, __assign({ my: 3, spacing: 3, alignItems: "stretch" }, { children: [isOrderActivated ? (_jsx(DashboardMenuCard, { title: "Place New Order", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Place New Order',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('order', { id: 'new' });
                        } }, void 0)) : null, !!creditLimit && (_jsx(DashboardMenuCard, { title: "Pay Back Credit", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Pay',
                                '!Click Source': CLICK_SOURCE,
                            });
                            props.showSecondAccount();
                        }, isPrimary: true }, void 0)), _jsx(DashboardMenuCard, { title: "Pay ".concat(distributor === null || distributor === void 0 ? void 0 : distributor.name), onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Pay',
                                '!Click Source': CLICK_SOURCE,
                            });
                            props.showAccountDetails();
                        } }, void 0), !!creditLimit && (_jsx(DashboardMenuCard, { title: "Get Credit", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Get Credit',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('new-funding-request', { id: 'new' });
                        } }, void 0)), isOrderActivated ? (_jsx(DashboardMenuCard, { title: "View Orders", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'View Orders',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('orders');
                        }, notification: {
                            count: (orders === null || orders === void 0 ? void 0 : orders.count) || 0,
                            message: (orders === null || orders === void 0 ? void 0 : orders.message) || '',
                            onClick: function () { return router.go('orders'); },
                        } }, void 0)) : null, _jsx(DashboardMenuCard, { title: "View Payments", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'View Payments',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('payments');
                        }, notification: {
                            count: (payments === null || payments === void 0 ? void 0 : payments.count) || 0,
                            message: (payments === null || payments === void 0 ? void 0 : payments.message) || '',
                            onClick: function () { return router.go('payments'); },
                        } }, void 0), creditLimit ? (_jsx(DashboardMenuCard, { title: "View Credits", onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'View Credits',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('funding-requests');
                        }, notification: {
                            count: (funding === null || funding === void 0 ? void 0 : funding.count) || 0,
                            message: (funding === null || funding === void 0 ? void 0 : funding.message) || '',
                            onClick: function () { return router.go('funding-requests'); },
                        } }, void 0)) : null] }), void 0))] }), void 0));
};
