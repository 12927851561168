import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useToast } from '@chakra-ui/react';
import { basicSearch } from '@obtainly/utils';
import { useOrderItem } from '@obtainly/hooks';
import { SearchInput, ContentLoader } from '../web-shared';
import { OrderItem as OrderItemShared } from './OrderItem';
export var OrderProducts = function (_a) {
    var order = _a.order, products = _a.products, refetchOrder = _a.refetchOrder, editableOrder = _a.editableOrder;
    var _b = React.useState(''), search = _b[0], setSearch = _b[1];
    var filteredProducts = React.useMemo(function () {
        return products && search
            ? products.filter(function (product) {
                return basicSearch(product.name, search);
            })
            : products || [];
    }, [products, search]);
    return (_jsxs(_Fragment, { children: [_jsx(SearchInput, { mb: 4, value: search, onChange: setSearch, placeholder: "Search products", isDisabled: !(products === null || products === void 0 ? void 0 : products.length) }, void 0), _jsx(ContentLoader, { contentUnavailable: !(filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.length), errorMessage: "No products found" }, void 0), order && (filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.length)
                ? filteredProducts.map(function (product) {
                    var _a, _b;
                    return (_jsx(OrderProduct, { product: product, orderItem: (_b = (_a = Object.values(order.items)) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, function (orderItem) {
                            return orderItem.product_id === product._id;
                        }), order: order, refetchOrder: refetchOrder, editableOrder: editableOrder }, product._id));
                })
                : null] }, void 0));
};
var OrderProduct = function (_a) {
    var product = _a.product, orderItem = _a.orderItem, order = _a.order, refetchOrder = _a.refetchOrder, editableOrder = _a.editableOrder;
    var toast = useToast();
    var _b = useOrderItem({ order: order, product: product, orderItem: orderItem }, refetchOrder, function (message) {
        return toast({ status: 'error', description: message, position: 'bottom' });
    }), quantity = _b.quantity, setQuantity = _b.setQuantity, rate = _b.rate, updateItem = _b.updateItem, isUpdating = _b.isUpdating, isRemoving = _b.isRemoving;
    return (_jsx(OrderItemShared, { type: "product", product: product, orderItemLogic: {
            rate: rate,
            quantity: quantity,
            setQuantity: setQuantity,
            isUpdating: isUpdating,
            isRemoving: isRemoving,
            updateItem: updateItem,
        }, freeItem: orderItem === null || orderItem === void 0 ? void 0 : orderItem.free_item, bg: quantity > 0 ? 'gray.50' : 'white', borderColor: quantity > 0 ? 'gray.100' : 'gray.100', orderModel: order, editableOrder: editableOrder }, void 0));
};
