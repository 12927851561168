var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { OrderTag } from '@obtainly/models';
import { useOrderItem } from '@obtainly/hooks';
import { ContentLoader } from '../web-shared/ContentLoader';
import { OrderItem as OrderItemShared } from './OrderItem';
export var OrderItems = function (_a) {
    var _b;
    var order = _a.order, refetchOrder = _a.refetchOrder, editableOrder = _a.editableOrder;
    var itemGroups = React.useMemo(function () {
        var count = function (itemsObject) {
            return itemsObject ? Object.keys(itemsObject).length : 0;
        };
        var groups = [
            {
                title: 'Removed Items',
                titleColor: 'critical.600',
                showTitle: true,
                items: order.removed_items ? Object.values(order.removed_items) : [],
            },
            {
                title: 'New Items',
                titleColor: 'success.600',
                showTitle: true,
                items: order.new_items ? Object.values(order.new_items) : [],
            },
            {
                title: 'Modified Items',
                titleColor: 'warning.600',
                showTitle: true,
                items: order.modified_items ? Object.values(order.modified_items) : [],
            },
            {
                title: 'Untouched Items',
                titleColor: 'gray.600',
                showTitle: !!count(order.removed_items) ||
                    !!count(order.new_items) ||
                    !!count(order.modified_items),
                items: order.tag === OrderTag.Draft
                    ? Object.values(order.items)
                    : order.same_items
                        ? Object.values(order.same_items)
                        : [],
            },
        ];
        groups = groups.filter(function (group) { return group.items.length; });
        if (!groups.length) {
            groups.push({
                title: 'Items',
                titleColor: 'gray.600',
                showTitle: false,
                items: order.items ? Object.values(order.items) : [],
            });
        }
        return groups;
    }, [
        order.removed_items,
        order.new_items,
        order.modified_items,
        order.same_items,
        order.tag,
        order.items,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !((_b = Object.keys(order.items || {})) === null || _b === void 0 ? void 0 : _b.length), errorMessage: 'No products in this order. Click "All Products" to add products.' }, void 0), itemGroups.map(function (group) { return (_jsx(Box, __assign({ mb: 6 }, { children: _jsxs(_Fragment, { children: [group.showTitle ? (_jsx(Text, __assign({ fontSize: "sm", fontWeight: "semibold", color: group.titleColor, textTransform: "uppercase", letterSpacing: "0.3px" }, { children: group.title }), void 0)) : null, (group === null || group === void 0 ? void 0 : group.items)
                            ? group.items
                                .slice()
                                .reverse()
                                .map(function (item) { return (_jsx(OrderItem, { order: order, orderItem: item, refetchOrder: refetchOrder, editableOrder: editableOrder }, item._id)); })
                            : null] }, void 0) }), group === null || group === void 0 ? void 0 : group.title)); })] }, void 0));
};
var OrderItem = function (_a) {
    var _b;
    var order = _a.order, orderItem = _a.orderItem, refetchOrder = _a.refetchOrder, editableOrder = _a.editableOrder;
    var toast = useToast();
    var _c = useOrderItem({ orderItem: orderItem }, refetchOrder, function (message) {
        return toast({ status: 'error', description: message, position: 'bottom' });
    }), quantity = _c.quantity, setQuantity = _c.setQuantity, rate = _c.rate, updateItem = _c.updateItem, isUpdating = _c.isUpdating, isRemoving = _c.isRemoving;
    var changes = React.useMemo(function () {
        var _a, _b, _c, _d, _e;
        var _changes = {
            count: 0,
            modified: 0,
            added: 0,
            removed: 0,
        };
        var productId = (_a = orderItem === null || orderItem === void 0 ? void 0 : orderItem.product) === null || _a === void 0 ? void 0 : _a._id;
        if (productId) {
            var original = (_b = order === null || order === void 0 ? void 0 : order.original_items) === null || _b === void 0 ? void 0 : _b[productId];
            var modified = (_c = order === null || order === void 0 ? void 0 : order.modified_items) === null || _c === void 0 ? void 0 : _c[productId];
            var added = (_d = order === null || order === void 0 ? void 0 : order.new_items) === null || _d === void 0 ? void 0 : _d[productId];
            var removed = (_e = order === null || order === void 0 ? void 0 : order.removed_items) === null || _e === void 0 ? void 0 : _e[productId];
            if (modified && original) {
                _changes.modified = original.quantity;
                _changes.count += 1;
            }
            else if (added) {
                _changes.added = added.quantity;
                _changes.count += 1;
            }
            else if (removed) {
                _changes.removed = removed.quantity;
                _changes.count += 1;
            }
        }
        return _changes;
    }, [
        order === null || order === void 0 ? void 0 : order.original_items,
        order === null || order === void 0 ? void 0 : order.modified_items,
        order === null || order === void 0 ? void 0 : order.new_items,
        order === null || order === void 0 ? void 0 : order.removed_items,
        (_b = orderItem === null || orderItem === void 0 ? void 0 : orderItem.product) === null || _b === void 0 ? void 0 : _b._id,
    ]);
    return (_jsx(OrderItemShared, { type: "item", product: orderItem === null || orderItem === void 0 ? void 0 : orderItem.product, orderItemLogic: {
            rate: rate,
            quantity: quantity,
            setQuantity: setQuantity,
            isUpdating: isUpdating,
            isRemoving: isRemoving,
            updateItem: updateItem,
        }, freeItem: orderItem === null || orderItem === void 0 ? void 0 : orderItem.free_item, changes: changes, display: quantity > 0 ? 'block' : 'none', orderModel: order, editableOrder: editableOrder }, void 0));
};
