var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text, Flex, SimpleGrid, Box } from '@chakra-ui/layout';
import { FiMapPin, FiUserCheck } from 'react-icons/fi';
import { parseOrderMeta } from '@obtainly/utils';
import { OrderMetaTag, OrderTag, OrderType } from '@obtainly/models';
import { CardHeader } from '../web-shared';
export var OrderLocationHeader = function (_a) {
    var order = _a.order;
    var metaObject = parseOrderMeta(order || null, {}).metaObject;
    var placedBy = metaObject === null || metaObject === void 0 ? void 0 : metaObject[OrderMetaTag.PlacedBy];
    var cancelledBy = metaObject === null || metaObject === void 0 ? void 0 : metaObject[OrderMetaTag.CancelledBy];
    var shipTo = React.useMemo(function () {
        var _a, _b, _c;
        if ((order === null || order === void 0 ? void 0 : order.type) === OrderType.Pickup || !order)
            return;
        return (((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.meta_obj) === null || _a === void 0 ? void 0 : _a[OrderMetaTag.MerchantLocation]) === null || _b === void 0 ? void 0 : _b.merchant_location) === null || _c === void 0 ? void 0 : _c.address) || '');
    }, [order]);
    return shipTo || placedBy ? (_jsx(CardHeader, __assign({ py: 3, bg: "#fcfcfd", borderBottom: "1px solid", borderColor: "gray.100", display: "block" }, { children: _jsxs(SimpleGrid, __assign({ gridGap: 4, templateColumns: "auto auto" }, { children: [shipTo ? (_jsxs(Flex, __assign({ isTruncated: true, fontSize: "sm", fontWeight: "medium", align: "center", gridGap: 2 }, { children: [_jsx(Box, __assign({ isTruncated: false }, { children: _jsx(FiMapPin, {}, void 0) }), void 0), _jsx(Text, __assign({ isTruncated: true }, { children: "Ship to: ".concat(shipTo) }), void 0)] }), void 0)) : null, placedBy && (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Pending ? (_jsxs(Flex, __assign({ isTruncated: true, fontSize: "sm", fontWeight: "medium", align: "center", gridGap: 2 }, { children: [_jsx(Box, __assign({ isTruncated: false }, { children: _jsx(FiUserCheck, { size: 14 }, void 0) }), void 0), _jsxs(Text, __assign({ isTruncated: true }, { children: ["By ", placedBy.firstname] }), void 0)] }), void 0)) : null, cancelledBy && (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Cancelled ? (_jsxs(Flex, __assign({ isTruncated: true, fontSize: "sm", fontWeight: "medium", align: "center", gridGap: 2 }, { children: [_jsx(Box, __assign({ isTruncated: false }, { children: _jsx(FiUserCheck, { size: 14 }, void 0) }), void 0), _jsxs(Text, __assign({ isTruncated: true }, { children: ["Cancelled by ", cancelledBy.firstname] }), void 0)] }), void 0)) : null] }), void 0) }), void 0)) : null;
};
