var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useParams } from 'react-router';
import { OrderTag, OrderType, ComplaintTag, } from '@obtainly/models';
import { useRouter } from 'src/core/hooks';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import ComplaintForm from 'src/complaint/components/ComplaintForm';
import { ContentLoader, Layout } from 'src/core/components';
import { useToast } from 'src/core/hooks';
export var OrderConfirmationPage = function () {
    var router = useRouter();
    var toast = useToast().toast;
    // Get order
    var orderId = useParams().id;
    var _a = useQueryModel('order.read', {
        _id: orderId,
    }), orders = _a.data, isLoadingOrder = _a.isLoading, refetchOrder = _a.refetch;
    var order = React.useMemo(function () {
        return orders === null || orders === void 0 ? void 0 : orders[0];
    }, [orders]);
    // Get order items
    var _b = useQueryModel('order_item.read_by_order', {
        order_id: order === null || order === void 0 ? void 0 : order._id,
    }), orderItems = _b.data, isLoadingOrderItems = _b.isLoading, refetchOrderItems = _b.refetch;
    var isLoading = isLoadingOrder || isLoadingOrderItems;
    var updateOrder = useMutateModel('order.update_tag');
    var handleUpdate = function () {
        var newTag = OrderTag.Delivered;
        if (order) {
            updateOrder.mutate({
                _id: order._id,
                old_tag: order.tag,
                new_tag: newTag,
                meta: '',
            }, {
                onSuccess: function () {
                    refetchOrder();
                    refetchOrderItems();
                    toast({ description: 'Order updated', status: 'success' });
                    router.go('orders');
                },
                onError: function (_a) {
                    var message = _a.message;
                    refetchOrder();
                    toast({ description: message, status: 'error' });
                },
            });
        }
    };
    return (_jsxs(Layout, __assign({ headerProps: { title: 'Confirm Order', goBack: router.goBack } }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && !!order && (_jsx(_Fragment, { children: order.tag === OrderTag.InTransit ||
                    (order.tag === OrderTag.Processing &&
                        order.type === OrderType.Pickup) ? (_jsx(ComplaintForm, { action: "create", onCancel: router.goBack, onSuccess: handleUpdate, merchant: order.merchant, order: order, isUpdatingOrderTag: updateOrder.isLoading, sequenceData: [
                        {
                            tag: ComplaintTag.Shortages,
                            products: orderItems === null || orderItems === void 0 ? void 0 : orderItems.map(function (orderItem) {
                                return __assign(__assign({}, orderItem.product), { price: orderItem.amount / orderItem.quantity });
                            }),
                        },
                        {
                            tag: ComplaintTag.Expiry,
                            products: orderItems === null || orderItems === void 0 ? void 0 : orderItems.map(function (orderItem) {
                                return __assign(__assign({}, orderItem.product), { price: orderItem.amount / orderItem.quantity });
                            }),
                        },
                        {
                            tag: ComplaintTag.Damages,
                            products: orderItems === null || orderItems === void 0 ? void 0 : orderItems.map(function (orderItem) {
                                return __assign(__assign({}, orderItem.product), { price: orderItem.amount / orderItem.quantity });
                            }),
                        },
                    ] }, void 0)) : (_jsx(_Fragment, { children: _jsx(ContentLoader, { contentUnavailable: true, errorSummary: "This order has already been confirmed." }, void 0) }, void 0)) }, void 0))] }), void 0));
};
