import React from 'react';
import { useMutateModel } from '@obtainly/http';
import { useDebounce } from '@obtainly/hooks';
var DEBOUNCE_DELAY = 1500;
export function useOrderItem(_a, onSuccess, onError) {
    var order = _a.order, orderItem = _a.orderItem, product = _a.product;
    var upsertOrderItem = useMutateModel('order_item.upsert');
    var removeOrderItem = useMutateModel('order_item.remove');
    var _b = React.useState(DEBOUNCE_DELAY), delay = _b[0], setDelay = _b[1];
    var _c = React.useState((orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) || 0), quantity = _c[0], setQuantity = _c[1];
    var debouncedQuantity = useDebounce(quantity, delay);
    var _d = React.useState((orderItem === null || orderItem === void 0 ? void 0 : orderItem.amount) ? orderItem.amount / quantity : (product === null || product === void 0 ? void 0 : product.price) || 0), rate = _d[0], setRate = _d[1];
    var debouncedRate = useDebounce(rate, 2000);
    var firstRender = React.useRef(true);
    var handleChange = function (quantity, rate) {
        var _a, _b;
        var productPrice = (product === null || product === void 0 ? void 0 : product.price) || 0;
        quantity = (_a = quantity !== null && quantity !== void 0 ? quantity : orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) !== null && _a !== void 0 ? _a : 0;
        rate = (_b = rate !== null && rate !== void 0 ? rate : ((orderItem === null || orderItem === void 0 ? void 0 : orderItem.amount) || 0) / quantity) !== null && _b !== void 0 ? _b : productPrice;
        var amount = quantity * rate;
        if (quantity) {
            upsertOrderItem.mutate({
                _id: orderItem === null || orderItem === void 0 ? void 0 : orderItem._id,
                order_id: (orderItem === null || orderItem === void 0 ? void 0 : orderItem.order_id) || (order === null || order === void 0 ? void 0 : order._id),
                name: (product === null || product === void 0 ? void 0 : product.name) || (orderItem === null || orderItem === void 0 ? void 0 : orderItem.name),
                quantity: quantity,
                amount: amount,
                product_id: (orderItem === null || orderItem === void 0 ? void 0 : orderItem.product_id) || (product === null || product === void 0 ? void 0 : product._id),
            }, {
                onSuccess: function () { return onSuccess(); },
                onError: function (_a) {
                    var message = _a.message;
                    return onError(message);
                },
            });
        }
        else if (orderItem === null || orderItem === void 0 ? void 0 : orderItem._id) {
            removeOrderItem.mutate({
                _id: orderItem._id,
            }, {
                onSuccess: function () { return onSuccess(); },
                onError: function (_a) {
                    var message = _a.message;
                    return onError(message);
                },
            });
        }
    };
    React.useEffect(function () {
        if (!firstRender.current) {
            handleChange(debouncedQuantity, debouncedRate);
        }
        firstRender.current = false;
    }, [debouncedQuantity, debouncedRate]); // eslint-disable-line
    // Watch orderItem.quantity for changes
    React.useEffect(function () {
        setQuantity((orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) || 0);
    }, [orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity]);
    // Watch orderItem.amount for changes
    React.useEffect(function () {
        !(orderItem === null || orderItem === void 0 ? void 0 : orderItem.amount) && setRate((product === null || product === void 0 ? void 0 : product.price) || 0);
    }, [orderItem === null || orderItem === void 0 ? void 0 : orderItem.amount, product === null || product === void 0 ? void 0 : product.price, quantity]);
    return {
        quantity: quantity,
        setQuantity: setQuantity,
        rate: rate,
        setRate: setRate,
        updateItem: function (quantity, rate, delay) {
            if (delay !== null) {
                setDelay(delay);
                setTimeout(function () {
                    setDelay(DEBOUNCE_DELAY);
                }, delay + 100);
            }
            quantity !== null && setQuantity(quantity);
            rate !== null && setRate(rate);
        },
        isUpdating: upsertOrderItem.isLoading || removeOrderItem.isLoading,
        isRemoving: removeOrderItem.isLoading,
    };
}
