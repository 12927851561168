var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Card, CardBody, CardHeader } from '../web-shared';
import { OrderInvoice } from './OrderInvoice';
import { OrderLocationHeader } from './OrderLocationHeader';
export var OrderPreview = function (_a) {
    var order = _a.order;
    return (_jsxs(Card, { children: [_jsx(OrderLocationHeader, { order: order }, void 0), _jsx(CardHeader, { heading: "Order Invoice", pt: "20px" }, void 0), _jsx(CardBody, __assign({ pb: 3 }, { children: _jsx(OrderInvoice, { order: order }, void 0) }), void 0)] }, void 0));
};
