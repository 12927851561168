var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Heading, Flex, Box, Text, SimpleGrid } from '@chakra-ui/layout';
import { Helmet } from 'react-helmet';
export var Invoice = function () {
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("link", { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css" }, void 0), _jsx("style", { children: "\n            @page {\n                size: A4 potrait;\n                margin-top: 1px;\n                margin-bottom: 10px;\n                margin-left: 20px;\n            }\n\n            .column {\n                float: left;\n                padding: 5px;\n            }\n          " }, void 0)] }, void 0), _jsx("main", __assign({ className: "A4 portrait" }, { children: _jsx("section", __assign({ className: "sheet", id: "content-print" }, { children: _jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsx(Box, { children: _jsx(Heading, __assign({ as: "h4" }, { children: "Company" }), void 0) }, void 0), _jsxs(Box, { children: [_jsx(Heading, __assign({ as: "h4", color: "gray.600", textAlign: "right", fontWeight: "medium" }, { children: "Invoice" }), void 0), _jsxs(SimpleGrid, __assign({ columns: 2, gridColumnGap: 3 }, { children: [_jsx(Text, __assign({ color: "gray.600" }, { children: "Invoice Number" }), void 0), _jsx(Text, __assign({ textAlign: "right" }, { children: "#Q6YZJFVR3C" }), void 0), _jsx(Text, __assign({ color: "gray.600" }, { children: "Date" }), void 0), _jsx(Text, __assign({ textAlign: "right" }, { children: "Dec 22, 2021" }), void 0)] }), void 0)] }, void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
};
