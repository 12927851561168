var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Grid, Icon, Text } from '@chakra-ui/react';
import { useMessageConfig } from '@obtainly/hooks';
import { useAtom } from 'jotai';
import React from 'react';
import { IoNotifications } from 'react-icons/io5';
import { distributorsAtom } from 'src/core/store';
export var PublicInfo = function () {
    var _a;
    var distributor = useAtom(distributorsAtom)[0];
    var _b = useMessageConfig((_a = distributor === null || distributor === void 0 ? void 0 : distributor.active) === null || _a === void 0 ? void 0 : _a._id), isMessageActivated = _b.isMessageActivated, message = _b.message;
    if (!isMessageActivated)
        return null;
    return (_jsx(Container, __assign({ maxW: "container.lg", mt: "1.5rem" }, { children: _jsx(Box, __assign({ px: "1.5rem", py: "2rem", w: "full", bg: "primary.500", rounded: "md" }, { children: _jsxs(Grid, __assign({ templateColumns: "1fr 3rem", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ fontSize: "2xl", color: "white" }, { children: message || 'No Message to display' }), void 0), _jsxs(Box, __assign({ pos: "relative", w: "1.25rem" }, { children: [_jsx(Icon, { as: IoNotifications, fontSize: "3rem", color: "white" }, void 0), _jsx(Box, { pos: "absolute", right: "2px", top: "1px", borderWidth: "4px", borderColor: "primary.500", w: "1.25rem", h: "1.25rem", rounded: "full", bg: "white" }, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0));
};
