var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrderTag } from '@obtainly/models';
import { usePermission, useRouter } from 'src/core/hooks';
import { useOrderUpdateTag } from './useOrderUpdateTag';
export function useOrderStateMachine(order, refetchOrder) {
    var _a, _b;
    var router = useRouter();
    var userCan = usePermission().userCan;
    var updateOrderTag = useOrderUpdateTag(order, refetchOrder);
    var defaultOrderStates = (_a = {},
        _a[OrderTag.Draft] = function () {
            return {
                nextStates: [this[OrderTag.Pending]()],
            };
        },
        _a[OrderTag.Pending] = function () {
            return {
                title: 'Place Order',
                triggerButtonProps: {
                    colorScheme: 'success',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.Pending); },
                },
                triggerConfirmation: 'Are you sure you want to place this order?',
                userCan: !!order && !!(order === null || order === void 0 ? void 0 : order.amount) && userCan('create_order'),
                nextStates: [this[OrderTag.Processing](), this[OrderTag.Cancelled]()],
            };
        },
        _a[OrderTag.Processing] = function () {
            return {
                title: 'Process Order',
                triggerButtonProps: {
                    colorScheme: 'success',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.Processing); },
                },
                userCan: userCan('process_order'),
                nextStates: [
                    this[OrderTag.InTransit](),
                    this[OrderTag.Modify](),
                    this[OrderTag.Cancelled](),
                ],
            };
        },
        _a[OrderTag.Modify] = function () {
            return {
                title: 'Modify Order',
                triggerButtonProps: {
                    colorScheme: 'warning',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.Pending); },
                },
                userCan: userCan('ship_order'),
                nextStates: [],
            };
        },
        _a[OrderTag.InTransit] = function () {
            return {
                title: 'Ship Order',
                triggerButtonProps: {
                    colorScheme: 'success',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.InTransit); },
                },
                userCan: userCan('ship_order'),
                nextStates: [this[OrderTag.Delivered](), this[OrderTag.Cancelled]()],
            };
        },
        _a[OrderTag.Delivered] = function () {
            return {
                title: "Confirm ".concat(order === null || order === void 0 ? void 0 : order.type),
                triggerButtonProps: {
                    colorScheme: 'success',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () {
                        router.go('order-confirmation', { id: order === null || order === void 0 ? void 0 : order._id });
                    },
                },
                userCan: userCan('complete_order'),
                nextStates: [],
            };
        },
        _a[OrderTag.Cancelled] = function () {
            return {
                title: 'Cancel Order',
                triggerButtonProps: {
                    colorScheme: 'critical',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.Cancelled); },
                },
                userCan: (order === null || order === void 0 ? void 0 : order.tag) !== OrderTag.InTransit && userCan('cancel_order'),
                triggerConfirmation: 'Are you sure you want to cancel your order?',
                nextStates: [],
            };
        },
        _a);
    var orderStatesByType = {
        delivery: __assign({}, defaultOrderStates),
        pickup: __assign(__assign({}, defaultOrderStates), (_b = {}, _b[OrderTag.Processing] = function () {
            return {
                title: 'Process Order',
                triggerButtonProps: {
                    colorScheme: 'success',
                    isLoading: updateOrderTag.isLoading,
                    isDisabled: updateOrderTag.isLoading,
                    onClick: function () { return updateOrderTag.mutate(OrderTag.Processing); },
                },
                userCan: userCan('process_order'),
                nextStates: [this[OrderTag.Delivered](), this[OrderTag.Cancelled]()],
            };
        }, _b[OrderTag.InTransit] = function () {
            return { nextStates: [] };
        }, _b)),
    };
    return (order === null || order === void 0 ? void 0 : order.type) ? orderStatesByType[order.type] : null;
}
