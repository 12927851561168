import { useAtom, configAtom } from 'src/core/store';
import { useQueryModel } from '@obtainly/http';
export var AppVersionStatus;
(function (AppVersionStatus) {
    AppVersionStatus["OLD"] = "old";
    AppVersionStatus["LATEST"] = "latest";
    AppVersionStatus["UNSET"] = "unset";
    AppVersionStatus["UNKNOWN"] = "unknown";
})(AppVersionStatus || (AppVersionStatus = {}));
export function useAppVersion(key) {
    var appVersion = useAtom(configAtom)[0].appVersion;
    var _a = useQueryModel('config.read_by_key', { key: key || 'APP_VERSION.default' }, {
        cacheTime: 0,
        enabled: !!appVersion,
        refetchInterval: 900000,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    }), data = _a.data, isLoading = _a.isLoading, isError = _a.isError, isRefetching = _a.isRefetching;
    var appVersionStatus = isError
        ? AppVersionStatus.UNKNOWN
        : (data === null || data === void 0 ? void 0 : data.length)
            ? data[0].value === appVersion
                ? AppVersionStatus.LATEST
                : AppVersionStatus.OLD
            : AppVersionStatus.UNSET;
    return {
        appVersionStatus: appVersionStatus,
        loadingAppVersion: isLoading || isRefetching,
    };
}
