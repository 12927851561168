import { useAppInstallation, useAuth, useDashboard, useFetchNotifications, useProfileCompletion, } from 'src/core/hooks';
import { permissions } from 'src/core/config';
export var AppInit = function (_a) {
    var isOnline = _a.isOnline;
    useAuth(permissions, isOnline);
    useDashboard();
    useFetchNotifications();
    useProfileCompletion();
    useAppInstallation();
    return null;
};
