var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrderTag } from '@obtainly/models';
import { useMutateModel } from '@obtainly/http';
import { useToast } from 'src/core/hooks';
import { analytics, Event } from 'src/core/utils';
export function useOrderUpdateTag(order, successCallback) {
    var toast = useToast().toast;
    var updateOrderTag = useMutateModel('order.update_tag');
    var mutate = function (newTag) {
        if (order) {
            updateOrderTag.mutate({
                _id: order._id || '',
                old_tag: order.tag,
                new_tag: newTag,
                meta: '',
            }, {
                onSuccess: function () {
                    var isNewOrder = newTag === OrderTag.Pending;
                    analytics.track(isNewOrder ? Event.OrderPlacement : Event.OrderUpdate, { order_id: order === null || order === void 0 ? void 0 : order._id, old_tag: order.tag, new_tag: newTag });
                    successCallback();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    toast({
                        description: isNewOrder
                            ? 'Your order has been placed successfully'
                            : 'Order updated',
                        status: 'success',
                    });
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ description: message, status: 'error' });
                },
            });
        }
    };
    return __assign(__assign({}, updateOrderTag), { mutate: mutate });
}
