var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { VStack, Grid } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { OrderTag } from '@obtainly/models';
import { useOrder, useOrderConfig, useProducts } from '@obtainly/hooks';
import { OrderPicker, OrderPreview } from '@obtainly/ui/web-mobile';
import { ErrorBox } from '@obtainly/ui/web-shared';
import { ContentLoader, Layout } from 'src/core/components';
import { OrderForm, OrderActions, OrderNotifications } from '../components';
import { useOrderStateMachine } from '../hooks/useOrderStateMachine';
import { distributorsAtom, useAtom, userAtom } from 'src/core/store';
export var Order = function () {
    var orderId = useParams().id;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useOrder(orderId), order = _a.order, editableOrder = _a.editableOrder, loadingOrder = _a.loadingOrder, refetchOrder = _a.refetchOrder, refetchingOrder = _a.refetchingOrder;
    var _b = useOrderConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id, user === null || user === void 0 ? void 0 : user._id), isOrderActivated = _b.isOrderActivated, isLoadingOrderConfig = _b.isLoading;
    var stopNewOrder = useMemo(function () {
        return !isLoadingOrderConfig && !loadingOrder
            ? !isOrderActivated
                ? order
                    ? order.tag === OrderTag.Draft
                    : true
                : false
            : false;
    }, [isLoadingOrderConfig, isOrderActivated, loadingOrder, order]);
    var orderStates = useOrderStateMachine(order || null, refetchOrder);
    var _c = useProducts({
        distributor_id: order === null || order === void 0 ? void 0 : order.distributor_id,
        merchant_id: order === null || order === void 0 ? void 0 : order.merchant_id,
    }), products = _c.products, loadingProducts = _c.loadingProducts;
    return (_jsxs(Layout, __assign({ headerProps: {
            title: (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Draft
                ? "Draft Order"
                : order
                    ? "Order #".concat((order === null || order === void 0 ? void 0 : order.ref) || '')
                    : 'New Order',
        } }, { children: [_jsx(ContentLoader, { isLoading: (loadingOrder || loadingProducts) && !stopNewOrder }, void 0), stopNewOrder && (_jsx(Box, __assign({ pt: "30px" }, { children: _jsx(ErrorBox, { title: "Service unavailable", message: "Sorry, your order can not be placed at the moment" }, void 0) }), void 0)), !loadingOrder &&
                !loadingProducts &&
                !isLoadingOrderConfig &&
                !stopNewOrder ? (_jsxs(_Fragment, { children: [order && orderStates ? (_jsx(OrderActions, { order: order, orderStates: orderStates, loadingOrder: loadingOrder, refetchingOrder: refetchingOrder }, void 0)) : null, _jsx(Grid, __assign({ my: 3, templateColumns: ['1fr', '1fr'], gridGap: 5 }, { children: _jsx(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: order && products ? (_jsxs(_Fragment, { children: [order.tag !== OrderTag.Draft && (_jsx(OrderNotifications, { order: order }, void 0)), editableOrder && refetchOrder ? (_jsx(OrderPicker, { order: order, products: products, editableOrder: editableOrder, refetchOrder: refetchOrder, refetchingOrder: refetchingOrder }, void 0)) : (_jsx(OrderPreview, { order: order }, void 0))] }, void 0)) : (_jsx(OrderForm, { order: order }, void 0)) }), void 0) }), void 0), order && orderStates ? (_jsx(OrderActions, { order: order, orderStates: orderStates, loadingOrder: loadingOrder, refetchingOrder: refetchingOrder }, void 0)) : null] }, void 0)) : null] }), void 0));
};
