import { useQueryModel } from '@obtainly/http';
export function useProductReadBy(data) {
    var action = data.merchant_id
        ? 'read_by_distributor_merchant'
        : 'read_by_distributor';
    var payload = !data.merchant_id
        ? { distributor_id: data.distributor_id }
        : data;
    var enabled = data.merchant_id
        ? !!data.distributor_id && !!data.merchant_id
        : !!data.distributor_id;
    return useQueryModel("product.".concat(action), payload, {
        enabled: enabled,
    });
}
