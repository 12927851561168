export var Card = {
    parts: ['card', 'header', 'body', 'footer'],
    baseStyle: {
        card: {
            bg: 'white',
            borderColor: 'gray.100',
            borderRadius: '8px',
            boxShadow: '1px 1px 4px 0px rgba(23, 28, 52, 0.04)',
            transition: 'all 300ms',
        },
        header: {
            // borderBottom: '1px solid',
            // borderColor: 'gray.50',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px 8px 0 0',
            justifyContent: 'space-between',
            padding: '15px 20px 0',
            // overflowX: 'auto',
            flexWrap: 'wrap',
        },
        body: {
            padding: '20px',
        },
        footer: {
            borderTop: '1px solid',
            borderColor: 'gray.100',
            padding: '20px',
        },
    },
};
