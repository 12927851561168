var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex, Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useRouter } from 'src/core/hooks';
import { ListMaker } from 'src/core/components';
import { ContentLoader, FundingRequestDetails } from '@obtainly/ui/web-shared';
export var FundingRequestsList = function (props) {
    var _a;
    return (_jsx(_Fragment, { children: ((_a = props.fundingRequests) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(ListMaker, { data: props.fundingRequests || [], component: _jsx(FundingRequest, {}, void 0) }, void 0)) : (_jsx(ContentLoader, { errorMessage: "No credits found", contentUnavailable: true }, void 0)) }, void 0));
};
var FundingRequest = function (_a) {
    var fundingRequest = _a.item;
    var navigate = useRouter('funding-request');
    if (!fundingRequest)
        return null;
    return (_jsx(_Fragment, { children: _jsxs(Box, __assign({ py: "20px", cursor: "pointer", borderWidth: "0 0 1px 0", borderStyle: "solid", borderColor: "gray.100", bg: "white", boxShadow: "none", justifyContent: "space-between", transform: "scale(1)", position: "static", zIndex: 50, transition: "transform 60ms cubic-bezier(0.4, 0, 1, 1)" }, { children: [_jsx(Box, __assign({ px: "20px" }, { children: _jsx(FundingRequestDetails, { fundingRequest: fundingRequest }, void 0) }), void 0), _jsx(Flex, __assign({ px: "20px", mt: 3 }, { children: _jsx(Button, __assign({ size: "sm", colorScheme: "primary", variant: "outline", onClick: function () { return navigate.switchParams({ id: fundingRequest._id }); }, isFullWidth: true }, { children: "View Credit" }), void 0) }), void 0)] }), fundingRequest._id) }, void 0));
};
