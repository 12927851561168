var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useQueryModel } from '@obtainly/http';
import { LedgerHead } from '@obtainly/models';
import { Card, CardBody } from 'src/core/components';
import { useAtom, userAtom, distributorsAtom } from 'src/core/store';
import { LedgerLogs } from 'src/ledger';
export var AccountStatement = function () {
    var _a;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _b = useQueryModel('ledger.read_by_owner_specific', {
        owner: user === null || user === void 0 ? void 0 : user._id,
        head: LedgerHead.Statements,
        subhead: "statements_".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id),
    }, { enabled: !!(user === null || user === void 0 ? void 0 : user._id) }), ledgers = _b.data, isLoading = _b.isLoading;
    return (_jsx(Card, { children: _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(LedgerLogs, { ledgerId: (_a = ledgers === null || ledgers === void 0 ? void 0 : ledgers[0]) === null || _a === void 0 ? void 0 : _a._id, isLoadingLedger: isLoading }, void 0) }), void 0) }, void 0));
};
