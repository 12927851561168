var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/system';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { HeaderMenu } from 'src/core/components/Header/HeaderMenu';
import { distributorsAtom, useAtom } from 'src/core/store';
export var DashboardHeader = function () {
    var sizes = useTheme().sizes;
    var _a = useAtom(distributorsAtom), _b = _a[0], distributor = _b.active, distributors = _b.all, setDistributors = _a[1];
    function handleDistributorChange(distributor) {
        setDistributors({ all: distributors, active: distributor });
    }
    return (_jsx(_Fragment, { children: _jsx(Box, __assign({ as: "header", left: 0, width: "100%", zIndex: 31 }, { children: _jsx(Box, __assign({ px: "20px", color: "white", bg: "primary.500" }, { children: _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "space-between", h: sizes.layout.pageHeader }, { children: [_jsxs(Menu, { children: [_jsx(MenuButton, __assign({ as: Button, bg: "none", _hover: { bg: 'none' }, _active: { bg: 'none' }, _focus: { bg: 'none' }, rightIcon: _jsx(Icon, { as: FiChevronDown, fontSize: "1.25rem" }, void 0) }, { children: _jsx(Heading, __assign({ color: "white", fontSize: "xl", fontWeight: "bold", noOfLines: 1, isTruncated: true }, { children: distributor === null || distributor === void 0 ? void 0 : distributor.name }), void 0) }), void 0), _jsx(MenuList, { children: distributors === null || distributors === void 0 ? void 0 : distributors.map(function (distributor) { return (_jsx(MenuItem, __assign({ onClick: function () { return handleDistributorChange(distributor); }, color: "black" }, { children: distributor.name }), distributor._id)); }) }, void 0)] }, void 0), _jsx(HeaderMenu, { colorScheme: "white" }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0));
};
