var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertDescription, AlertIcon, Box, Button, Checkbox, Flex, ScaleFade, useBreakpointValue, VStack, } from '@chakra-ui/react';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader, ContentLoader, IframeBox, } from '@obtainly/ui/web-shared';
import { formatUnixTime, trimHtmlTemplate } from '@obtainly/utils';
import { motion, useElementScroll, useSpring, useTransform, } from 'framer-motion';
import { useAtom } from 'jotai';
import { useMemo, useRef, useState } from 'react';
import { RiFileInfoFill } from 'react-icons/ri';
import { FormGroup, OtpDialog, PoweredBadge } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { distributorsAtom, fundingAtom, userAtom } from 'src/core/store';
export var FundingAgreementWrapper = function () {
    var _a = useState(false), checked = _a[0], setChecked = _a[1];
    var _b = useAtom(fundingAtom), _c = _b[0], step = _c.step, isAgreed = _c.isAgreed, amount = _c.amount, interestRate = _c.interestRate, duration = _c.duration, type = _c.type, setFundingAtom = _b[1];
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var viewPortRef = useRef(null);
    var scrollYProgress = useElementScroll(viewPortRef).scrollYProgress;
    var toast = useToast().toast;
    var formNavigationProps = useBreakpointValue({
        base: {
            position: 'fixed',
            left: '0px',
            right: '0px',
            bottom: '62px',
            bgColor: 'white',
            padding: '10px 15px',
            borderTop: 'gray.100 1px solid',
            borderBottom: 'gray.100 1px solid',
            align: 'center',
            justify: 'space-between',
            zIndex: 'sticky',
        },
        md: {
            position: 'initial',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            bgColor: 'transparent',
            padding: '0px',
            borderTop: 'none',
            borderBottom: 'none',
            align: 'center',
            justify: 'space-between',
            zIndex: undefined,
        },
    });
    var _d = useQueryModel('notification.generate_content', {
        template: 'credit_agreement_notification',
        parameters_json: JSON.stringify({
            date: formatUnixTime(Math.floor(Date.now() / 1000), 'dd MMM, yyyy - h:mm a'),
            signed: "".concat(user === null || user === void 0 ? void 0 : user.firstname, " ").concat(user === null || user === void 0 ? void 0 : user.lastname),
            interest_rate: interestRate,
            amount: amount,
            duration_days: duration,
            distributor_name: distributor === null || distributor === void 0 ? void 0 : distributor.name,
        }),
    }, {
        enabled: !!amount &&
            !!interestRate &&
            !!duration &&
            !!type &&
            !!user &&
            !!distributor,
    }), agreementContent = _d.data, loadingAgreement = _d.isLoading;
    var filteredContent = useMemo(function () {
        return trimHtmlTemplate(agreementContent || '');
    }, [agreementContent]);
    var generateOtp = useMutateModel('setting.generate_otp');
    var USER_TYPE = 'merchant';
    function handleGenerateOtp() {
        generateOtp.mutate({
            owner_type: USER_TYPE,
            owner: user === null || user === void 0 ? void 0 : user._id,
        }, {
            onSuccess: function () {
                toast({
                    description: 'OTP has been generated and sent to your Email',
                    status: 'success',
                });
            },
            onError: function (error) {
                var _a, _b;
                toast({
                    description: ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'An error occurred',
                    status: 'error',
                });
            },
        });
    }
    var animationValues = useTransform(scrollYProgress, [0, 0.001, 0.98, 1], [0, 0, 0, -100]);
    var springAnimation = useSpring(animationValues);
    return (_jsxs(_Fragment, { children: [_jsxs(Card, { children: [_jsx(CardHeader, { boxShadow: "sm", px: "20px", pt: "20px", pb: "15px", mb: "0px", heading: "Terms and Conditions" }, void 0), _jsxs(CardBody, __assign({ position: "relative", p: "0px", overflow: "hidden" }, { children: [_jsx(ContentLoader, { isLoading: loadingAgreement, contentUnavailable: !agreementContent, errorMessage: "Unable to load agreement" }, void 0), agreementContent ? (_jsx(motion.div, __assign({ style: {
                                    top: springAnimation,
                                    left: 0,
                                    right: 0,
                                    position: 'absolute',
                                } }, { children: _jsx(Box, __assign({ bgColor: "warning.100", pt: "15px", pb: "5px", px: "20px", boxShadow: "sm", w: "full" }, { children: _jsx(FormGroup, { children: _jsx(Checkbox, __assign({ name: "accepted", onChange: function (e) { return setChecked(e.target.checked); }, isChecked: checked, colorScheme: "warning", borderColor: "warning.600" }, { children: "I agree to terms and conditions." }), void 0) }, void 0) }), void 0) }), void 0)) : null, _jsx(Box, __assign({ ref: viewPortRef, maxH: "65vh", overflowY: "auto", p: "20px" }, { children: !loadingAgreement && !!agreementContent ? (_jsxs(VStack, __assign({ gap: "10px", align: "stretch", textAlign: "justify" }, { children: [_jsx(IframeBox, { title: "Funding agreement", content: filteredContent, mt: "50px" }, void 0), _jsxs(FormGroup, { children: [_jsx(Checkbox, __assign({ name: "accepted", onChange: function (e) { return setChecked(e.target.checked); }, isChecked: checked, onFocus: function (checkboxElement) {
                                                        return checkboxElement.currentTarget.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start',
                                                            inline: 'nearest',
                                                        });
                                                    }, colorScheme: "warning", borderColor: "warning.500" }, { children: "I have read and agree to terms and conditions stated above." }), void 0), _jsx(ScaleFade, __assign({ in: !checked, initialScale: 0 }, { children: _jsxs(Alert, __assign({ status: "error", borderRadius: "md", borderColor: "critical.100", bg: "critical.50", alignItems: "start", mt: "10px", color: "critical.600" }, { children: [_jsx(AlertIcon, { as: RiFileInfoFill, w: "24px", h: "24px" }, void 0), _jsx(AlertDescription, { children: "Agree to terms and conditions by checking the box above before submitting funding request." }, void 0)] }), void 0) }), void 0)] }, void 0)] }), void 0)) : null }), void 0)] }), void 0)] }, void 0), _jsx(OtpDialog, { isOpen: isAgreed, onClose: function () { return setFundingAtom(function (prev) { return (__assign(__assign({}, prev), { isAgreed: false })); }); }, onGenerateOtp: handleGenerateOtp }, void 0), _jsx(Flex, __assign({ mt: "30px", justifyContent: "center" }, { children: _jsx(PoweredBadge, {}, void 0) }), void 0), _jsxs(Flex, __assign({}, formNavigationProps, { children: [_jsx(Button, __assign({ variant: "outline", size: "sm", disabled: step <= 1, onClick: function () { return setFundingAtom(function (state) { return (__assign(__assign({}, state), { step: 1 })); }); } }, { children: "Back" }), void 0), _jsx(Button, __assign({ colorScheme: "primary", onClick: function () {
                            return setFundingAtom(function (prev) { return (__assign(__assign({}, prev), { isAgreed: true })); });
                        }, isLoading: generateOtp.isLoading, size: "sm", disabled: !checked || generateOtp.isLoading }, { children: "Agree and Submit Request" }), void 0)] }), void 0)] }, void 0));
};
