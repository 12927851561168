var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatRelative, fromUnixTime } from 'date-fns';
import { RiBankCardFill, RiNotification2Fill, RiShoppingBag2Fill, } from 'react-icons/ri';
import { NotificationTag } from '@obtainly/models';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody, Layout, ListMaker } from 'src/core/components';
import { useNotifications } from 'src/core/hooks';
export var Notifications = function () {
    var _a = useNotifications(), readNotifications = _a.read, unreadNotifications = _a.unread, clearNotifications = _a.readNotifications;
    React.useEffect(function () {
        return function () { return clearNotifications('all'); };
    }, []); // eslint-disable-line
    return (_jsxs(Layout, __assign({ headerProps: { title: 'Notifications' } }, { children: [_jsx(Card, { children: _jsx(CardBody, __assign({ p: 0 }, { children: !(unreadNotifications === null || unreadNotifications === void 0 ? void 0 : unreadNotifications.length) ? (_jsxs(Flex, __assign({ px: [10, 20], py: 10, flexDir: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }, { children: [_jsx(Box, __assign({ bg: "warning.50", color: "warning.200", borderRadius: 99999, p: 3 }, { children: _jsx(RiNotification2Fill, { size: 26 }, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "lg", fontWeight: "medium", mt: 2 }, { children: "No new notifications" }), void 0), _jsx(Text, __assign({ color: "gray.600", mt: 1 }, { children: "Stay tuned! New notifications about your orders and payments will show up here." }), void 0)] }), void 0)) : (_jsx(ListMaker, { data: unreadNotifications.slice().reverse(), component: _jsx(NotificationItem, {}, void 0) }, void 0)) }), void 0) }, void 0), readNotifications.length > 0 && (_jsxs(Box, __assign({ mt: 4 }, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.500", textTransform: "uppercase", fontWeight: "semibold", ml: "15px" }, { children: "Old Notifications" }), void 0), _jsx(Card, __assign({ mt: 1 }, { children: _jsx(CardBody, __assign({ p: 0 }, { children: _jsx(ListMaker, { data: readNotifications.slice().reverse(), component: _jsx(NotificationItem, { old: true }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0))] }), void 0));
};
var NotificationItem = function (_a) {
    var _b, _c;
    var old = _a.old, item = _a.item;
    var colorScheme = (item === null || item === void 0 ? void 0 : item.tag) === NotificationTag.Payment ? 'teal' : 'orange';
    var meta = item === null || item === void 0 ? void 0 : item.meta_obj;
    var notificationTitle = (_b = {},
        _b[NotificationTag.Order] = function () {
            return "You placed a new order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref);
        },
        _b[NotificationTag.OrderFor] = function () {
            return "Order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " has been placed for you");
        },
        _b[NotificationTag.OrderChange] = function () {
            return "Order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " is now ").concat(item === null || item === void 0 ? void 0 : item.meta_obj.status);
        },
        _b[NotificationTag.Payment] = function () {
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return "New payment of ".concat(amount);
        },
        _b[NotificationTag.Funding] = function () {
            return "Funding request #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " is now ").concat(item === null || item === void 0 ? void 0 : item.meta_obj.status);
        },
        _b);
    var notificationSummary = (_c = {},
        _c[NotificationTag.Order] = function () {
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return "You placed a new order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " (").concat(amount, ")");
        },
        _c[NotificationTag.OrderFor] = function () {
            var _a, _b;
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return "A new order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " (").concat(amount, ") has been placed for you by ").concat((_a = meta === null || meta === void 0 ? void 0 : meta.distributor_officer) === null || _a === void 0 ? void 0 : _a.firstname, " ").concat((_b = meta === null || meta === void 0 ? void 0 : meta.distributor_officer) === null || _b === void 0 ? void 0 : _b.lastname);
        },
        _c[NotificationTag.OrderChange] = function () {
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return "Your order #".concat(item === null || item === void 0 ? void 0 : item.meta_obj.ref, " (").concat(amount, ") is now ").concat(item === null || item === void 0 ? void 0 : item.meta_obj.status);
        },
        _c[NotificationTag.Payment] = function () {
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return "Payment of ".concat(amount, " has been received");
        },
        _c[NotificationTag.Funding] = function () {
            var amount = formatCurrency(item === null || item === void 0 ? void 0 : item.meta_obj.amount, {
                showCurrency: true,
            });
            return (item === null || item === void 0 ? void 0 : item.message) || "Funding of ".concat(amount, " has been received");
        },
        _c);
    return (_jsxs(Flex, __assign({ px: "15px", py: "10px", alignItems: "flex-start", borderBottom: "1px solid", borderColor: "gray.100", gridGap: 3 }, { children: [_jsx(Box, __assign({ p: 2, mb: 1, bg: "".concat(colorScheme, ".50"), color: "".concat(colorScheme, ".600"), borderRadius: 99999, flexShrink: 0 }, { children: (item === null || item === void 0 ? void 0 : item.tag) === NotificationTag.Order ? (_jsx(RiShoppingBag2Fill, { size: 14 }, void 0)) : (item === null || item === void 0 ? void 0 : item.tag) === NotificationTag.OrderChange ? (_jsx(RiShoppingBag2Fill, { size: 14 }, void 0)) : (_jsx(RiBankCardFill, { size: 14 }, void 0)) }), void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ fontWeight: old ? 'normal' : 'medium' }, { children: (item === null || item === void 0 ? void 0 : item.tag) ? notificationTitle[item === null || item === void 0 ? void 0 : item.tag]() : 'Notification' }), void 0), _jsx(Text, __assign({ fontSize: "sm", color: old ? 'gray.600' : 'body' }, { children: (item === null || item === void 0 ? void 0 : item.tag) ? notificationSummary[item === null || item === void 0 ? void 0 : item.tag]() : item === null || item === void 0 ? void 0 : item.message }), void 0), !!(item === null || item === void 0 ? void 0 : item.created) && (_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", textTransform: "capitalize" }, { children: formatRelative(fromUnixTime(item === null || item === void 0 ? void 0 : item.created), new Date()) }), void 0))] }, void 0)] }), void 0));
};
