var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import pluralize from 'pluralize';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { formatCurrency } from '@obtainly/utils';
import { NotificationStatus, NotificationTag, } from '@obtainly/models';
import { useAtom, userAtom, distributorsAtom, dashboardAtom, } from 'src/core/store';
import { addDays, getUnixTime } from 'date-fns';
export function useFetchNotifications() {
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useAtom(dashboardAtom), setDashboard = _a[1];
    var data = useQueryModel('notification.read_by_distributor_merchant_tags', {
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
        merchant_id: user === null || user === void 0 ? void 0 : user._id,
        tags: [
            NotificationTag.Order,
            NotificationTag.OrderFor,
            NotificationTag.Payment,
            NotificationTag.OrderChange,
            NotificationTag.Funding,
        ],
        from_timestamp: getUnixTime(addDays(new Date(), -6)),
    }, {
        queryKey: 'notifications',
        enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) && !!(user === null || user === void 0 ? void 0 : user._id),
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    }).data;
    // Sync notifications query with notifications in store
    React.useEffect(function () {
        // compare new data with data in the store
        // Parse JSON data in `meta` key
        var mappedData = data
            ? data.map(function (notification) {
                try {
                    return __assign(__assign({}, notification), { meta_obj: JSON.parse(notification.meta) });
                }
                catch (error) {
                    return __assign(__assign({}, notification), { meta_obj: {} });
                }
            })
            : [];
        setDashboard(function (state) { return (__assign(__assign({}, state), { notifications: mappedData })); });
    }, [data]); // eslint-disable-line
}
export function useNotifications() {
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useAtom(dashboardAtom), dashboard = _a[0], setDashboard = _a[1];
    var updateNotification = useMutateModel('notification.set_status');
    var updateNotifications = useMutateModel('notification.bulk_set_status');
    var read = React.useMemo(function () {
        var _a;
        if (!((_a = dashboard.notifications) === null || _a === void 0 ? void 0 : _a.length)) {
            return [];
        }
        return dashboard.notifications.filter(function (notification) {
            return notification.status === NotificationStatus.Seen;
        });
    }, [dashboard.notifications]);
    var unread = React.useMemo(function () {
        var _a;
        if (!((_a = dashboard.notifications) === null || _a === void 0 ? void 0 : _a.length)) {
            return [];
        }
        return dashboard.notifications.filter(function (notification) {
            return notification.status === NotificationStatus.Created;
        });
    }, [dashboard.notifications]);
    var payments = React.useMemo(function () {
        var data = unread.filter(function (_a) {
            var tag = _a.tag;
            return tag === NotificationTag.Payment;
        });
        var total = data.reduce(function (total, item) { return total + item.meta_obj.amount; }, 0);
        var amount = formatCurrency(total, { showCurrency: true });
        var paymentText = pluralize('payment', data.length);
        return {
            data: data,
            count: data.length,
            message: "".concat(data.length, " new ").concat(paymentText, " of ").concat(amount, " received"),
        };
    }, [unread]);
    var orders = React.useMemo(function () {
        var data = unread.filter(function (_a) {
            var tag = _a.tag;
            return [NotificationTag.Order, NotificationTag.OrderFor].includes(tag);
        });
        var total = data.reduce(function (total, item) { return total + item.meta_obj.amount; }, 0);
        var amount = formatCurrency(total, { showCurrency: true });
        var orderText = pluralize('order', data.length);
        var hasText = pluralize('has', data.length);
        return {
            data: data,
            count: data.length,
            message: "".concat(data.length, " new ").concat(orderText, " of ").concat(amount, " ").concat(hasText, " been received by ").concat(distributor === null || distributor === void 0 ? void 0 : distributor.name),
        };
    }, [unread, distributor]);
    var funding = React.useMemo(function () {
        var data = unread.filter(function (_a) {
            var tag = _a.tag;
            return tag === NotificationTag.Funding;
        });
        var total = data.reduce(function (total, item) { return total + item.meta_obj.amount; }, 0);
        var amount = formatCurrency(total, { showCurrency: true });
        var fundingText = pluralize('funding', data.length);
        return {
            data: data,
            count: data.length,
            message: data.length === 1
                ? data[0].message
                : "".concat(data.length, " new ").concat(fundingText, " of ").concat(amount),
        };
    }, [unread]);
    return {
        read: read,
        unread: unread,
        orders: orders,
        payments: payments,
        funding: funding,
        readNotification: function (notification) {
            updateNotification.mutate({
                _id: notification._id,
                status: NotificationStatus.Seen,
            });
        },
        readNotifications: function (type) {
            var _a;
            var notifications = type === 'orders'
                ? orders === null || orders === void 0 ? void 0 : orders.data
                : type === 'payments'
                    ? payments === null || payments === void 0 ? void 0 : payments.data
                    : type === 'funding'
                        ? funding === null || funding === void 0 ? void 0 : funding.data
                        : dashboard.notifications;
            if (!(notifications === null || notifications === void 0 ? void 0 : notifications.length)) {
                return;
            }
            // Read notifications in store
            var _ids = notifications.map(function (_a) {
                var _id = _a._id;
                return _id;
            });
            var newNotifications = (_a = dashboard.notifications) === null || _a === void 0 ? void 0 : _a.map(function (notification) {
                var status = _ids.includes(notification._id)
                    ? NotificationStatus.Seen
                    : notification.status;
                return __assign(__assign({}, notification), { status: status });
            });
            setDashboard(function (state) { return (__assign(__assign({}, state), { notifications: newNotifications })); });
            // Read notifications in DB
            var payload = {
                _ids: [],
                status: NotificationStatus.Seen,
            };
            notifications.forEach(function (notification) {
                !!notification._id && payload['_ids'].push(notification._id);
            });
            updateNotifications.mutate(payload);
        },
    };
}
