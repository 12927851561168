import { useQueryModel } from '@obtainly/http';
import { useMemo } from 'react';
export function useMessageConfig(distributorId, merchantId) {
    var _a = useQueryModel('config.read_key_dictionary', {}, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    }), configs = _a.data, isLoading = _a.isLoading;
    var isMessageActivated = useMemo(function () {
        var _a, _b, _c;
        if (!distributorId) {
            return false;
        }
        if ((configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.".concat(merchantId)]) &&
            ((_a = configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.".concat(merchantId)]) === null || _a === void 0 ? void 0 : _a.value) !== 'true') {
            return false;
        }
        if ((configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.".concat(distributorId)]) &&
            ((_b = configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.".concat(distributorId)]) === null || _b === void 0 ? void 0 : _b.value) !== 'true') {
            return false;
        }
        if ((configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.default"]) &&
            ((_c = configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE_ON.default"]) === null || _c === void 0 ? void 0 : _c.value) !== 'true') {
            return false;
        }
        return true;
    }, [merchantId, distributorId, configs]);
    var isForceMigrateActivated = useMemo(function () {
        var _a, _b;
        if (!distributorId) {
            return false;
        }
        if ((configs === null || configs === void 0 ? void 0 : configs["FORCE_MIGRATE_ON.".concat(distributorId)]) ||
            (configs === null || configs === void 0 ? void 0 : configs["FORCE_MIGRATE_ON.default"])) {
            if (((_a = configs === null || configs === void 0 ? void 0 : configs["FORCE_MIGRATE_ON.".concat(distributorId)]) === null || _a === void 0 ? void 0 : _a.value) === 'true') {
                return true;
            }
            else if (((_b = configs === null || configs === void 0 ? void 0 : configs["FORCE_MIGRATE_ON.default"]) === null || _b === void 0 ? void 0 : _b.value) === 'true') {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }, [distributorId, configs]);
    var migrateLink = useMemo(function () {
        var _a;
        return (_a = configs === null || configs === void 0 ? void 0 : configs["FORCE_MIGRATE_URL"]) === null || _a === void 0 ? void 0 : _a.value;
    }, [configs]);
    var message = useMemo(function () {
        var _a;
        return (_a = configs === null || configs === void 0 ? void 0 : configs["MIGRATE_MESSAGE"]) === null || _a === void 0 ? void 0 : _a.value;
    }, [configs]);
    return {
        isLoading: isLoading,
        isMessageActivated: isMessageActivated,
        message: message,
        migrateLink: migrateLink,
        isForceMigrateActivated: isForceMigrateActivated,
    };
}
