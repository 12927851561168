var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { fromUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
import { useAtom, dashboardAtom, userAtom, distributorsAtom } from '../store';
export function useDashboard() {
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useAtom(dashboardAtom), activeDate = _a[0].activeDate, setDashboard = _a[1];
    var payload = React.useMemo(function () {
        var date = activeDate ? fromUnixTime(activeDate) : new Date();
        return {
            _id: user === null || user === void 0 ? void 0 : user._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }, [activeDate, distributor, user]);
    var _b = useQueryModel('merchant.dashboard', payload, {
        enabled: !!payload._id,
    }), data = _b.data, isLoading = _b.isLoading;
    // Update dashboard store
    React.useEffect(function () {
        setDashboard(function (state) { return (__assign(__assign({}, state), { data: data || null, isLoading: isLoading })); });
    }, [data, isLoading]); // eslint-disable-line
    return {
        dashboard: data,
        isLoading: isLoading,
    };
}
