var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, GridItem, SimpleGrid, Text, Box } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { MerchantTag, OrderType, } from '@obtainly/models';
import { useMutateModel } from '@obtainly/http';
import { Card, CardBody, CardHeader, RadioCardGroup } from 'src/core/components';
import { usePermission, useRouter, useToast } from 'src/core/hooks';
import { useAtom, userAtom, distributorsAtom } from 'src/core/store';
import { analytics, Event } from 'src/core/utils';
export var OrderForm = function (_a) {
    var order = _a.order;
    var router = useRouter();
    var userCan = usePermission().userCan;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var toast = useToast().toast;
    // Order stuff
    var _b = React.useState(false), isAutoCreating = _b[0], setIsAutoCreating = _b[1];
    // Setup merchant
    var _c = React.useState(), merchant = _c[0], setMerchant = _c[1];
    React.useEffect(function () {
        user && setMerchant(user);
    }, [user]);
    // Setup type
    var _d = React.useState(OrderType.Delivery), type = _d[0], setType = _d[1];
    var typeOptions = React.useMemo(function () {
        var _a, _b, _c;
        var _merchantOrderType = (_c = (_b = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags) === null || _a === void 0 ? void 0 : _a["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _b === void 0 ? void 0 : _b[MerchantTag.ALLOWED_ORDER_TYPE]) === null || _c === void 0 ? void 0 : _c.value;
        var options = [];
        setType(null);
        if ([OrderType.Delivery, OrderType.Both].includes(_merchantOrderType)) {
            options.push({
                value: OrderType.Delivery,
                label: 'Delivery',
                description: 'Your order will be delivered to you',
            });
        }
        if ([OrderType.Pickup, OrderType.Both].includes(_merchantOrderType)) {
            options.push({
                value: OrderType.Pickup,
                label: 'Pickup',
                description: "Pick up your order at Supplier's warehouse",
            });
        }
        if (options.length && _merchantOrderType !== OrderType.Both) {
            setType(options[0].value);
        }
        return options;
    }, [merchant, distributor === null || distributor === void 0 ? void 0 : distributor._id]);
    // Setup location
    var _e = React.useState(null), location = _e[0], setLocation = _e[1];
    var locationOptions = React.useMemo(function () {
        var _a, _b, _c;
        var locations = ((_b = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.merchant_locations) === null || _a === void 0 ? void 0 : _a["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _b === void 0 ? void 0 : _b.filter(function (loc) { return loc.status === 'active' && !!loc._id; }).map(function (loc) { return ({
            value: "".concat(loc._id),
            label: loc.business_name,
            description: loc.address,
        }); })) || [];
        locations.length === 1 && setLocation((_c = locations[0]) === null || _c === void 0 ? void 0 : _c.value);
        return locations;
    }, [merchant, distributor === null || distributor === void 0 ? void 0 : distributor._id]);
    // Create order stuff
    var createOrder = useMutateModel('order.begin');
    var createLocationOrder = useMutateModel('order.begin_for_location');
    var handleCreate = function (autoCreate) {
        setIsAutoCreating(autoCreate || false);
        if (!(order === null || order === void 0 ? void 0 : order._id)) {
            if (type === OrderType.Pickup) {
                createOrder.mutate({
                    type: type,
                    meta: '',
                    merchant_id: merchant === null || merchant === void 0 ? void 0 : merchant._id,
                    distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
                    lead_time: 0,
                }, {
                    onSuccess: function (id) {
                        analytics.track(Event.OrderBegin, { order_id: id });
                        router.replace('order', { id: id });
                    },
                    onError: function (error) {
                        return toast({
                            description: error.message,
                            status: 'error',
                        });
                    },
                });
            }
            else {
                createLocationOrder.mutate({
                    type: type,
                    meta: '',
                    merchant_id: merchant === null || merchant === void 0 ? void 0 : merchant._id,
                    distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
                    merchant_location_id: location,
                    lead_time: 0,
                }, {
                    onSuccess: function (id) {
                        analytics.track(Event.OrderBegin, { order_id: id });
                        router.replace('order', { id: id });
                    },
                    onError: function (error) {
                        return toast({
                            description: error.message,
                            status: 'error',
                        });
                    },
                });
            }
        }
    };
    // Auto create order on mount for everybody
    React.useEffect(function () {
        merchant &&
            distributor &&
            (typeOptions === null || typeOptions === void 0 ? void 0 : typeOptions.length) === 1 &&
            locationOptions.length === 1 &&
            handleCreate(true);
    }, [merchant, distributor, typeOptions, locationOptions]); // eslint-disable-line
    return (_jsxs(Card, __assign({ as: "section" }, { children: [_jsx(CardHeader, { heading: "Order Details", display: "none", border: 0 }, void 0), _jsx(CardBody, { children: isAutoCreating ? (_jsxs(Flex, __assign({ flexDir: "column", justifyContent: "center", alignItems: "center", py: 5 }, { children: [_jsx(Spinner, { color: "gray.600" }, void 0), _jsx(Text, __assign({ mt: 4, color: "gray.600" }, { children: "Creating your order..." }), void 0)] }), void 0)) : (_jsxs(SimpleGrid, __assign({ columns: 1, spacing: "20px" }, { children: [!order && (_jsxs(Box, { children: [_jsx(Text, __assign({ mb: 1, fontSize: "md", fontWeight: "medium" }, { children: "Select order type" }), void 0), _jsx(GridItem, { children: _jsx(RadioCardGroup, { value: type, onChange: function (value) { return setType(value); }, options: typeOptions }, void 0) }, void 0)] }, void 0)), !!(locationOptions === null || locationOptions === void 0 ? void 0 : locationOptions.length) && type === OrderType.Delivery && (_jsxs(Box, { children: [_jsx(Text, __assign({ mb: 1, fontSize: "md", fontWeight: "medium" }, { children: "Select shipping address" }), void 0), _jsx(GridItem, { children: _jsx(RadioCardGroup, { value: location, onChange: function (value) { return setLocation(value); }, options: locationOptions, columns: 1 }, void 0) }, void 0)] }, void 0)), _jsx(GridItem, { children: !order && userCan('begin_order') ? (_jsx(Button, __assign({ colorScheme: "primary", isLoading: createOrder.isLoading || createLocationOrder.isLoading, isDisabled: !merchant ||
                                    !type ||
                                    (type === OrderType.Delivery && !location), onClick: function () { return handleCreate(); } }, { children: "Continue" }), void 0)) : null }, void 0)] }), void 0)) }, void 0)] }), void 0));
};
