export var parseVirtualAccount = function (virtualAccount) {
    if (virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.value) {
        try {
            return JSON.parse(virtualAccount.value);
        }
        catch (_a) {
            return null;
        }
    }
    return null;
};
