var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { ContentLoader, ListMaker } from 'src/core/components';
import { formatUnixTime } from '@obtainly/utils';
import { useRouter } from 'src/core/hooks';
export var ComplaintsList = function (props) {
    var _a;
    return (_jsx(_Fragment, { children: ((_a = props.complaints) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(ListMaker, { data: props.complaints || [], component: _jsx(Complaint, {}, void 0) }, void 0)) : (_jsx(ContentLoader, { errorSummary: "No complaints found", contentUnavailable: true }, void 0)) }, void 0));
};
var Complaint = function (_a) {
    var item = _a.item;
    var navigate = useRouter('complaint');
    return (_jsx(Box, __assign({ px: "20px", py: "10px", cursor: "pointer", borderBottom: "1px solid", borderColor: "gray.100", justifyContent: "space-between", onClick: function () { return navigate.switchParams({ id: item === null || item === void 0 ? void 0 : item._id }); } }, { children: _jsxs(Box, { children: [_jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsxs(Text, __assign({ display: "block", mr: 4, isTruncated: true }, { children: ["#", item === null || item === void 0 ? void 0 : item.ref] }), void 0), _jsx(Text, __assign({ fontSize: "sm", width: "25%", display: "block", wordBreak: "keep-all", color: "gray.600", textAlign: "right" }, { children: formatUnixTime(item === null || item === void 0 ? void 0 : item.created, 'MMM d') }), void 0)] }), void 0), _jsxs(Flex, __assign({ my: 2, gridGap: 4 }, { children: [_jsx(Badge, __assign({ colorScheme: ['shortages', 'damages'].includes(item === null || item === void 0 ? void 0 : item.tag)
                                ? 'critical'
                                : 'gray' }, { children: item === null || item === void 0 ? void 0 : item.tag }), void 0), _jsxs(Badge, __assign({ colorScheme: (item === null || item === void 0 ? void 0 : item.status) === 'closed' ? 'success' : 'critical' }, { children: [item === null || item === void 0 ? void 0 : item.status, ' '] }), void 0)] }), void 0)] }, void 0) }), item === null || item === void 0 ? void 0 : item._id));
};
