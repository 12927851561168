var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Text } from '@chakra-ui/layout';
import { formatCurrency } from '@obtainly/utils';
import { Alert, AlertDescription, AlertIcon, Box, } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { useCreditLimit } from '@obtainly/hooks';
export var CreditInfoBox = function (_a) {
    var merchantId = _a.merchantId, distributor = _a.distributor, renderComponent = _a.renderComponent, boxProps = __rest(_a, ["merchantId", "distributor", "renderComponent"]);
    var merchants = useQueryModel('merchant.read', { _id: merchantId }, { enabled: !!merchantId }).data;
    var merchant = useMemo(function () {
        return (merchants === null || merchants === void 0 ? void 0 : merchants[0]) || null;
    }, [merchants]);
    var _b = useCreditLimit((distributor === null || distributor === void 0 ? void 0 : distributor._id) || '', merchantId), isLoading = _b.isLoading, creditLimit = _b.creditLimit, availableCreditLimit = _b.availableCreditLimit;
    return (_jsx(_Fragment, { children: !isLoading && !!merchant && creditLimit && (_jsx(_Fragment, { children: renderComponent ? (renderComponent({ creditLimit: creditLimit })) : (_jsx(Box, __assign({}, boxProps, { children: _jsxs(Alert, __assign({ status: "info", borderRadius: "6px", mt: "10px", alignItems: "start" }, { children: [_jsx(AlertIcon, {}, void 0), _jsx(AlertDescription, { children: _jsxs(Text, { children: [merchant === null || merchant === void 0 ? void 0 : merchant.business_name, " has a total credit limit of up to", ' ', _jsx(Text, __assign({ as: "span", fontWeight: "semibold" }, { children: formatCurrency(creditLimit, {
                                            showCurrency: true,
                                            decimals: 0,
                                        }) }), void 0), ' ', "on Obtainly. Available credit is", ' ', _jsx(Text, __assign({ as: "span", fontWeight: "semibold" }, { children: formatCurrency(availableCreditLimit || 0, {
                                            showCurrency: true,
                                            decimals: 0,
                                        }) }), void 0), "."] }, void 0) }, void 0)] }), void 0) }), void 0)) }, void 0)) }, void 0));
};
