var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Tabs, TabList, Tab } from '@chakra-ui/tabs';
import { Spinner } from '@chakra-ui/react';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody, CardFooter, CardHeader, OrderPromotionItem, OrderPromotions, } from '../web-shared';
import { OrderProducts } from './OrderProducts';
import { OrderItems } from './OrderItems';
import { OrderLocationHeader } from './OrderLocationHeader';
var OrderPickerTab;
(function (OrderPickerTab) {
    OrderPickerTab[OrderPickerTab["All"] = 0] = "All";
    OrderPickerTab[OrderPickerTab["Selected"] = 1] = "Selected";
    OrderPickerTab[OrderPickerTab["Promotions"] = 2] = "Promotions";
})(OrderPickerTab || (OrderPickerTab = {}));
export var OrderPicker = function (_a) {
    var _b, _c, _d, _e, _f;
    var order = _a.order, products = _a.products, editableOrder = _a.editableOrder, refetchOrder = _a.refetchOrder, refetchingOrder = _a.refetchingOrder;
    var _g = React.useState(OrderPickerTab.All), tabIndex = _g[0], setTabIndex = _g[1];
    React.useEffect(function () {
        !editableOrder && setTabIndex(OrderPickerTab.Selected);
    }, [editableOrder]);
    var promotionsCount = (((_b = order.promotion_values) === null || _b === void 0 ? void 0 : _b.length) || 0) +
        (((_c = order.promotion_products) === null || _c === void 0 ? void 0 : _c.length) || 0);
    return (_jsxs(Card, { children: [_jsx(OrderLocationHeader, { order: order }, void 0), _jsx(CardHeader, __assign({ heading: "Items" }, { children: _jsx(OrderTotal, { order: order }, void 0) }), void 0), _jsx(Tabs, __assign({ pt: "10px", px: "20px", index: tabIndex, borderBottom: "1px solid", borderColor: "gray.100", display: editableOrder ? 'block' : 'none', onChange: function (tabIndex) {
                    setTabIndex(tabIndex);
                } }, { children: _jsxs(TabList, __assign({ borderColor: "white", flexWrap: "wrap", gridGap: 2 }, { children: [_jsxs(Tab, __assign({ isDisabled: !editableOrder }, { children: ["All Products (", (products === null || products === void 0 ? void 0 : products.length) || '0', ")"] }), void 0), _jsxs(Tab, { children: ["Selected Products (", ((_d = Object.keys(order.items || {})) === null || _d === void 0 ? void 0 : _d.length) || '0', ")"] }, void 0), promotionsCount > 0 && _jsxs(Tab, { children: ["Promotions (", promotionsCount, ")"] }, void 0)] }), void 0) }), void 0), _jsx(CardBody, { children: _jsx(Box, __assign({ mb: 3 }, { children: tabIndex === OrderPickerTab.All ? (_jsx(OrderProducts, { order: order, products: products, refetchOrder: refetchOrder, editableOrder: editableOrder }, void 0)) : tabIndex === OrderPickerTab.Selected ? (_jsx(OrderItems, { order: order, refetchOrder: refetchOrder, editableOrder: editableOrder }, void 0)) : tabIndex === OrderPickerTab.Promotions ? (_jsx(OrderPromotions, { order: order }, void 0)) : null }), void 0) }, void 0), _jsxs(CardFooter, { children: [tabIndex !== OrderPickerTab.Promotions &&
                        ((_e = order.promotion_values) === null || _e === void 0 ? void 0 : _e.length) ? (_jsxs(Box, __assign({ mb: "15px", pb: "10px", borderBottom: "1px solid", borderColor: "gray.100" }, { children: [_jsx(Text, __assign({ fontSize: "sm", textTransform: "uppercase", color: "gray.500", fontWeight: "semibold" }, { children: "Active Promotions" }), void 0), (_f = order.promotion_values) === null || _f === void 0 ? void 0 : _f.map(function (promotionValue) { return (_jsx(OrderPromotionItem, { order: order, promotionValue: promotionValue }, promotionValue._id)); })] }), void 0)) : null, _jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ fontWeight: "semibold" }, { children: "Order Total:" }), void 0), _jsx(OrderTotal, { order: order, refetchingOrder: refetchingOrder }, void 0)] }), void 0)] }, void 0)] }, void 0));
};
var OrderTotal = function (_a) {
    var order = _a.order, refetchingOrder = _a.refetchingOrder;
    return (_jsx(_Fragment, { children: order.amount ? (_jsx(Text, __assign({ as: "span", fontSize: "lg", color: "primary.600", fontWeight: "bold" }, { children: refetchingOrder ? (_jsx(Spinner, { size: "sm", color: "primary.200" }, void 0)) : (formatCurrency(order.subtotal || 0, {
                showCurrency: true,
            })) }), void 0)) : null }, void 0));
};
