var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { Box, Flex, Text } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency, getFundingOverdueState } from '@obtainly/utils';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { RiFileList2Fill } from 'react-icons/ri';
import { distributorsAtom, userAtom } from 'src/core/store';
var OverdueBanner = function () {
    var _a, _b;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _c = useQueryModel('funding_request.read_by_merchant_distributor', {
        merchant_id: user === null || user === void 0 ? void 0 : user._id,
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }, { enabled: !!(user === null || user === void 0 ? void 0 : user._id) || !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), fundingRequests = _c.data, isLoading = _c.isLoading;
    var overdueFunding = useMemo(function () {
        if (!fundingRequests)
            return null;
        return fundingRequests.filter(function (funding) { return getFundingOverdueState(funding).isOverdue; });
    }, [fundingRequests]);
    if (isLoading || !overdueFunding || overdueFunding.length === 0)
        return null;
    return (_jsx(Box, __assign({ m: 4, bg: "red.500", px: "0.75rem", py: "1.25rem", rounded: "md" }, { children: _jsxs(Flex, __assign({ alignItems: "center", gap: "1rem" }, { children: [_jsx(Box, __assign({ bg: "white", rounded: "full", p: "0.5rem" }, { children: _jsx(RiFileList2Fill, { size: "2rem", color: "#E53E3E" }, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "lg", fontWeight: "medium", color: "white" }, { children: "You have ".concat(formatCurrency(Math.abs(((_b = (_a = overdueFunding.reverse()[0]) === null || _a === void 0 ? void 0 : _a.ledger) === null || _b === void 0 ? void 0 : _b.balance) || 0), {
                        showCurrency: true,
                    }), " overdue for Payment") }), void 0)] }), void 0) }), void 0));
};
export default OverdueBanner;
