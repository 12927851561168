var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { IconButton } from '@chakra-ui/button';
import { Spinner } from '@chakra-ui/react';
import { Box, Text, Flex, Badge } from '@chakra-ui/layout';
import pluralize from 'pluralize';
import { FiCheck, FiTrash2 } from 'react-icons/fi';
import { OrderTag, } from '@obtainly/models';
import { asset, formatCurrency } from '@obtainly/utils';
import { ImageBox, OrderPromotionItem, QuantityInput } from '../web-shared';
export var OrderItem = function (_a) {
    var _b;
    var type = _a.type, product = _a.product, orderItemLogic = _a.orderItemLogic, changes = _a.changes, freeItem = _a.freeItem, order = _a.orderModel, editableOrder = _a.editableOrder, props = __rest(_a, ["type", "product", "orderItemLogic", "changes", "freeItem", "orderModel", "editableOrder"]);
    var promotionProduct = useMemo(function () {
        var _a;
        if (!((_a = order.promotion_products) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        return order.promotion_products.find(function (promotionProduct) {
            return promotionProduct.product_id === (product === null || product === void 0 ? void 0 : product._id);
        });
    }, [order.promotion_products, product === null || product === void 0 ? void 0 : product._id]);
    return (_jsx(Box, __assign({ mt: 2, p: "15px", boxShadow: "sm", borderRadius: "8px", border: "1px solid", borderColor: "gray.100", transition: "opacity 300ms" }, props, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Flex, __assign({ width: "60px", justifyContent: "center", alignItems: "flex-start" }, { children: type === 'product' && (orderItemLogic === null || orderItemLogic === void 0 ? void 0 : orderItemLogic.quantity) > 0 ? (_jsx(Flex, __assign({ color: "success.600", justifyContent: "center", alignItems: "center", height: "50px" }, { children: _jsx(FiCheck, { size: 24 }, void 0) }), void 0)) : (_jsx(Flex, __assign({ justifyContent: "center", alignItems: "center" }, { children: _jsx(ImageBox, { src: asset((_b = product === null || product === void 0 ? void 0 : product.upload) === null || _b === void 0 ? void 0 : _b.path), height: "50px" }, void 0) }), void 0)) }), void 0), _jsxs(Box, __assign({ width: "100%" }, { children: [_jsxs(Box, __assign({ mb: 2 }, { children: [_jsx(Text, __assign({ as: "span", fontWeight: "semibold" }, { children: product === null || product === void 0 ? void 0 : product.name }), void 0), _jsxs(Text, __assign({ mt: 1, as: "span", color: "gray.600", fontSize: "sm", display: "flex", gridGap: 1 }, { children: [product === null || product === void 0 ? void 0 : product.case_count, " ", pluralize('unit', product === null || product === void 0 ? void 0 : product.case_count), "/case -", ' ', formatCurrency(orderItemLogic.rate, { showCurrency: true })] }), void 0)] }), void 0), editableOrder ? (_jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsx(QuantityInput, { value: orderItemLogic.quantity * ((product === null || product === void 0 ? void 0 : product.case_count) || 0), onChange: function (value) {
                                        (product === null || product === void 0 ? void 0 : product.case_count) &&
                                            orderItemLogic.setQuantity(value / (product === null || product === void 0 ? void 0 : product.case_count));
                                    }, isValueEditable: true, mpu: product === null || product === void 0 ? void 0 : product.mpu, isDisabled: orderItemLogic.isUpdating, caseCount: product === null || product === void 0 ? void 0 : product.case_count, unit: "case" }, void 0), orderItemLogic.quantity > 0 && (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Draft ? (_jsx(Flex, __assign({ w: "48px", justifyContent: "flex-end" }, { children: _jsx(IconButton, { size: "sm", variant: "ghost", boxShadow: "sm", bg: "white", width: "34px", border: "1px solid", borderColor: "critical.50", colorScheme: "critical", "aria-label": "Delete", icon: _jsx(FiTrash2, {}, void 0), isLoading: orderItemLogic.isRemoving, isDisabled: orderItemLogic.isUpdating || !orderItemLogic.quantity, onClick: function () { return orderItemLogic.updateItem(0, null, 0); } }, void 0) }), void 0)) : null] }), void 0)) : orderItemLogic.quantity > 0 ? (_jsxs(Flex, __assign({ gridGap: 2, alignItems: "center" }, { children: [_jsxs(Text, { children: [orderItemLogic.quantity, pluralize(' case', orderItemLogic.quantity)] }, void 0), (changes === null || changes === void 0 ? void 0 : changes.modified) ? (_jsxs(Text, __assign({ color: "gray.600", textDecor: "line-through" }, { children: [changes.modified, pluralize(' case', changes.modified)] }), void 0)) : null, _jsx(OrderItemBadge, { changes: changes }, void 0)] }), void 0)) : null, _jsx(Box, __assign({ ml: -1, mt: 3 }, { children: orderItemLogic.quantity && freeItem ? (_jsx(OrderItemFreeItem, { freeItem: freeItem }, void 0)) : promotionProduct ? (_jsx(OrderPromotionItem, { order: order, promotionProduct: promotionProduct, context: "OrderItem" }, void 0)) : null }), void 0), _jsxs(Flex, __assign({ mt: 3, alignItems: "center", justifyContent: "space-between" }, { children: [orderItemLogic.quantity > 0 ? (_jsx(Text, __assign({ fontWeight: "semibold", alignSelf: "center" }, { children: formatCurrency(orderItemLogic.quantity * orderItemLogic.rate, {
                                        showCurrency: true,
                                    }) }), void 0)) : null, orderItemLogic.isUpdating && (_jsx(Spinner, { size: "sm", color: "gray.500" }, void 0))] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
var OrderItemFreeItem = function (_a) {
    var _b;
    var freeItem = _a.freeItem;
    return (_jsxs(Flex, __assign({ bg: "gray.50", gridGap: 2, padding: "4px", flexWrap: "wrap", alignItems: "center", border: "1px solid", borderColor: "gray.100", borderRadius: "6px" }, { children: [_jsx(Badge, __assign({ colorScheme: "success" }, { children: "Free Cases" }), void 0), _jsxs(Text, __assign({ fontSize: "sm" }, { children: [(_b = freeItem.product) === null || _b === void 0 ? void 0 : _b.name, _jsxs(Text, __assign({ as: "span", fontWeight: "medium" }, { children: [' ', "x ", freeItem.quantity] }), void 0)] }), void 0)] }), void 0));
};
var OrderItemBadge = function (props) {
    var _a = props.changes || {}, count = _a.count, modified = _a.modified, added = _a.added, removed = _a.removed;
    return count ? (_jsx(Badge, __assign({ alignSelf: "center", colorScheme: modified ? 'warning' : added ? 'success' : removed ? 'critical' : 'gray' }, { children: modified ? 'Modified' : added ? 'New' : removed ? 'Removed' : null }), void 0)) : null;
};
