var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Container, Heading, Image, Link, Text, } from '@chakra-ui/react';
import { useMessageConfig } from '@obtainly/hooks';
import { useAtom } from 'jotai';
import React from 'react';
import { distributorsAtom } from 'src/core/store';
import LunchIcon from './lunch-icon.svg';
export var LaunchBanner = function () {
    var distributor = useAtom(distributorsAtom)[0].active;
    var _a = useMessageConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id), migrateLink = _a.migrateLink, isForceMigrateActivated = _a.isForceMigrateActivated;
    return (_jsx(_Fragment, { children: isForceMigrateActivated ? (_jsx(Container, __assign({ maxW: "container.lg" }, { children: _jsxs(Box, __assign({ p: "1.25rem", rounded: "lg", bg: "secondary.lightBlue", borderBottomWidth: "4px", borderBottomColor: "secondary.lightBlueBorderColor", boxShadow: "1px 1px 4px 0px rgba(23, 28, 52, 0.04)" }, { children: [_jsx(Image, { src: LunchIcon, alt: "business completion Icon", w: "5rem", h: "5rem" }, void 0), _jsx(Heading, __assign({ fontSize: "3xl", lineHeight: 1.4 }, { children: "Introducing Our New App" }), void 0), _jsx(Text, __assign({ fontSize: "lg", mt: "0.5rem", lineHeight: 1.7 }, { children: "We are thrilled to present our new app, designed to exceed your expectations. We have created a better user experience to empower you more." }), void 0), _jsx(Button, __assign({ as: Link, href: migrateLink, colorScheme: "primary", size: "lg", mt: "1rem" }, { children: "Open the new app" }), void 0)] }), void 0) }), void 0)) : null }, void 0));
};
