import { INTEREST_RATE } from '@obtainly/utils';
import { atom } from 'jotai';
export var initialFundingState = {
    step: 1,
    amount: 0,
    interestRate: INTEREST_RATE,
    duration: 30,
    isAgreed: false,
};
export var fundingAtom = atom(initialFundingState);
