var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { RiCheckboxCircleFill, RiErrorWarningFill } from 'react-icons/ri';
export function PasswordResetAlert(_a) {
    var status = _a.status, message = _a.message;
    var isSuccessful = status === 'success';
    return (_jsxs(SimpleGrid, __assign({ gridTemplateColumns: "auto 1fr", gridGap: "10px" }, { children: [_jsx(Box, __assign({ fontSize: "xx-large", color: "".concat(status, ".600") }, { children: isSuccessful ? _jsx(RiCheckboxCircleFill, {}, void 0) : _jsx(RiErrorWarningFill, {}, void 0) }), void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "lg", fontWeight: "semibold" }, { children: isSuccessful ? 'Successful' : 'Something went wrong' }), void 0), _jsx(Text, __assign({ fontSize: "sm", color: "gray.700" }, { children: message }), void 0)] }, void 0)] }), void 0));
}
