var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import { ContentLoader } from '@obtainly/ui/web-shared';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, Layout, Logo } from 'src/core/components';
import { useRouter, useToast } from 'src/core/hooks';
import { PasswordRecoveryForm, PasswordResetAlert } from '../components';
import { authAtom, useAtom } from 'src/core/store';
import { formatDistance } from 'date-fns';
export function ResetPasswordWithCode() {
    var code = useParams().code;
    var toast = useToast().toast;
    var navigate = useRouter();
    var token = useAtom(authAtom)[0].token;
    var isAuthenticated = !!token;
    var _a = useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = useState(null), response = _b[0], setResponse = _b[1];
    var _c = useState(null), timeToGo = _c[0], setTimeToGo = _c[1];
    var recoveryData = useMemo(function () {
        var _a, _b;
        return ((_a = response === null || response === void 0 ? void 0 : response.auth_generated.data) === null || _a === void 0 ? void 0 : _a.length) &&
            ((_b = response.auth_merchant) === null || _b === void 0 ? void 0 : _b.phone)
            ? {
                ttl: response.auth_generated.data[0].ttl,
                phone: response.auth_merchant.phone,
                recoveryCode: response.auth_generated.data[0].key,
            }
            : null;
    }, [response]);
    var _d = useMutateModel('merchant.recover_forgotten_auth'), isLoading = _d.isLoading, mutate = _d.mutate;
    useEffect(function () {
        var interval = null;
        if (recoveryData === null || recoveryData === void 0 ? void 0 : recoveryData.ttl) {
            interval = setInterval(function () {
                var currentDate = new Date();
                var timeNow = Math.floor(currentDate.getTime() / 1000);
                var minsLeft = Math.floor((recoveryData.ttl - timeNow) / 60);
                var formattedTime = formatDistance(currentDate, recoveryData.ttl * 1000);
                if (minsLeft < 0) {
                    setErrorMessage("This link has expired ".concat(formattedTime, " ago."));
                    setTimeToGo(null);
                }
                else {
                    setErrorMessage(null);
                    setTimeToGo(formattedTime);
                }
            }, 1000);
        }
        return function () {
            interval && clearInterval(interval);
        };
    }, [recoveryData]);
    useEffect(function () {
        function initialize() {
            if (isAuthenticated) {
                navigate.go('/');
                return;
            }
            if (code) {
                setErrorMessage(null);
                setResponse(null);
                mutate({
                    recovery_code: code,
                    commit: false,
                    hash: '',
                }, {
                    onSuccess: function (response) {
                        var _a;
                        var auth_generated = response.auth_generated;
                        if (auth_generated.code === 200 && ((_a = auth_generated.data) === null || _a === void 0 ? void 0 : _a.length)) {
                            setResponse(response);
                        }
                        else {
                            setErrorMessage(typeof auth_generated.message === 'string'
                                ? auth_generated.message
                                : '');
                        }
                    },
                    onError: function (_a) {
                        var message = _a.message;
                        setErrorMessage(message);
                        toast({
                            status: 'error',
                            description: message || 'An unknown error occurred',
                        });
                    },
                });
            }
        }
        initialize();
    }, [isAuthenticated, code]); // eslint-disable-line
    return (_jsx(Layout, __assign({ bg: "gray.50", hideNavigation: true }, { children: isLoading ? (_jsx(ContentLoader, { isLoading: true }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ justifyContent: "center", my: "40px" }, { children: _jsx(Logo, {}, void 0) }), void 0), recoveryData && !errorMessage ? (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ mb: "20px", textAlign: "center" }, { children: [_jsx(Text, __assign({ fontSize: "larger", fontWeight: "semibold", color: "primary.600" }, { children: "Update password" }), void 0), _jsxs(Text, { children: ["This link will expire soon. You have ", timeToGo, " to update your password."] }, void 0)] }), void 0), _jsx(Card, { children: _jsx(CardBody, { children: _jsx(PasswordRecoveryForm, { recoveryCode: recoveryData.recoveryCode, phone: recoveryData.phone }, void 0) }, void 0) }, void 0)] }, void 0)) : null, errorMessage || !recoveryData ? (_jsx(Card, { children: _jsx(CardBody, { children: _jsx(PasswordResetAlert, { status: "critical", message: errorMessage || 'This link is invalid or expired.' }, void 0) }, void 0) }, void 0)) : null, _jsx(Text, __assign({ mt: "20px", color: "primary.600", textAlign: "center" }, { children: _jsx(Link, __assign({ to: "/login" }, { children: "Back to login" }), void 0) }), void 0)] }, void 0)) }), void 0));
}
