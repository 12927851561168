import mixpanel from 'mixpanel-browser';
var Mixpanel = /** @class */ (function () {
    function Mixpanel(token) {
        this.initiated = false;
        if (!token) {
            // eslint-disable-next-line
            console.log("Mixpanel not initiated. It's nothing serious, just add the project token to your environment variables :)");
            return;
        }
        mixpanel.init(token, { debug: true });
        this.initiated = true;
    }
    /**
     * Event properties on mixpanel are capitalized and arranged alpahbetically.
     * We want our custom properties to fit in and show up first.
     * Introducing, `formatProperties`!
     * Ex. `click_source` becomes `!Click Source`, you gerrit?
     * @param properties
     * @returns
     */
    Mixpanel.prototype.formatProperties = function (properties) {
        if (!properties) {
            return properties;
        }
        var newProperties = {};
        var propertyArray = Object.entries(properties);
        propertyArray.forEach(function (_a) {
            var key = _a[0], value = _a[1];
            var words = key.replace('_', ' ').split(' ');
            words = words.map(function (word) {
                return word.charAt(0).toUpperCase() + word.substring(1);
            });
            var transformedKey = "!".concat(words.join(' '));
            newProperties[transformedKey] = value;
        });
        return newProperties;
    };
    Mixpanel.prototype.track = function (eventName, properties, options) {
        if (!this.initiated) {
            return;
        }
        properties = (options === null || options === void 0 ? void 0 : options.formatProperties)
            ? this.formatProperties(properties)
            : properties;
        mixpanel.track(eventName, properties);
    };
    Mixpanel.prototype.trackClick = function (properties) {
        mixpanel.track('Click', properties);
    };
    Mixpanel.prototype.trackPageView = function (properties) {
        var _a;
        var idRegex = new RegExp(/[A-Fa-f0-9]{24}/gm);
        // Replace ID in link with {id} to avoid multiple different links
        if ((_a = properties['!Link'].match(idRegex)) === null || _a === void 0 ? void 0 : _a.length) {
            properties['!Link'] = properties['!Link'].replace(idRegex, '{id}');
        }
        mixpanel.track('Page View', properties);
    };
    Mixpanel.prototype.identifyUser = function (distinctId) {
        mixpanel.identify(distinctId);
    };
    Mixpanel.prototype.setUser = function (properties) {
        mixpanel.people.set(properties);
    };
    return Mixpanel;
}());
export { Mixpanel };
