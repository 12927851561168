var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex, useTheme, Box, Center } from '@chakra-ui/react';
import { RiNotification2Fill, RiUser3Fill } from 'react-icons/ri';
import { useNotifications, useRouter } from 'src/core/hooks';
export var HeaderMenu = function (_a) {
    var colorScheme = _a.colorScheme;
    var router = useRouter();
    var sizes = useTheme().sizes;
    var unreadNotifications = useNotifications().unread;
    return (_jsx(Flex, __assign({ alignItems: "center", justifyContent: "space-between", h: sizes.layout.pageHeader }, { children: _jsxs(Flex, __assign({ color: colorScheme, fontSize: "22px", alignItems: "center", gridGap: 3 }, { children: [_jsxs(Flex, __assign({ w: "28px", h: "28px", cursor: "pointer", justifyContent: "center", alignItems: "center", position: "relative", onClick: function () { return router.go('notifications'); } }, { children: [!!unreadNotifications.length && (_jsx(Box, { display: "inline-block", w: "8px", h: "8px", borderRadius: "8px", bg: "critical.500", position: "absolute", top: "-3px", right: "-3px" }, void 0)), _jsx(RiNotification2Fill, {}, void 0)] }), void 0), _jsx(Center, __assign({ w: "28px", h: "28px", cursor: "pointer", color: colorScheme, onClick: function () { return router.go('settings'); } }, { children: _jsx(RiUser3Fill, {}, void 0) }), void 0)] }), void 0) }), void 0));
};
