var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HStack, Button, Box, Text, useTheme } from '@chakra-ui/react';
import { AppVersionStatus, useAppVersion } from 'src/core/hooks';
import { analytics, Event } from 'src/core/utils';
export var UpdatePrompt = function () {
    var sizes = useTheme().sizes;
    var appVersionStatus = useAppVersion().appVersionStatus;
    var updateApp = function () {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(function (reg) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (reg) {
                            // await reg.update()
                            analytics.track(Event.AppUpdate);
                            reg.unregister().then(function () {
                                window.location.reload();
                            });
                        }
                        else {
                            window.location.reload();
                        }
                        return [2 /*return*/];
                    });
                });
            });
        }
        else {
            console.warn('Service worker not supported.');
            window.location.reload();
        }
    };
    return (_jsx(_Fragment, { children: appVersionStatus === AppVersionStatus.OLD && (_jsx(Box, __assign({ position: "relative", width: "100%", height: sizes.layout.appPrompt }, { children: _jsx(Box, __assign({ bgColor: "primary.600", boxShadow: "sm", position: "fixed", w: "full", zIndex: "30", px: "20px" }, { children: _jsxs(HStack, __assign({ justify: "space-between", w: "100%", height: sizes.layout.appPrompt, gridGap: 2 }, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "white" }, { children: "A new version of Obtainly is available. Update now for better experience." }), void 0), _jsx(Box, { children: _jsx(Button, __assign({ onClick: updateApp, size: "sm" }, { children: "Update" }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0)) }, void 0));
};
