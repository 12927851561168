import { atom } from 'jotai';
export var profileAtom = atom({
    isLoading: false,
    isRefetching: false,
    refetchDocuments: function () {
        return;
    },
    documents: [],
    documentsDict: {},
    unverified: [],
    unverifiedCount: 0,
    verified: [],
    verifiedCount: 0,
    unset: [],
    unsetCount: 0,
    requiredDocumentCount: 0,
    isComplete: false,
});
