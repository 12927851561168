var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from '@chakra-ui/react';
import { useQueryParams, useRouter } from '@obtainly/hooks';
import { useMemo } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, AuthLayout } from 'src/core/components';
import { authAtom, useAtom } from 'src/core/store';
import { PasswordResetAlert, PasswordResetForm } from '../components';
export function ResetPassword() {
    var navigate = useRouter('reset_password');
    var action = useQueryParams().action;
    var token = useAtom(authAtom)[0].token;
    var isAuthenticated = !!token;
    var verified = useMemo(function () {
        return action === 'sent';
    }, [action]);
    function onSuccess() {
        navigate.switchQuery({
            action: 'sent',
        });
    }
    if (isAuthenticated) {
        return _jsx(Redirect, { to: "/" }, void 0);
    }
    return (_jsxs(AuthLayout, { children: [_jsx(Box, { mt: "40px" }, void 0), verified ? (_jsx(Card, { children: _jsx(CardBody, { children: _jsx(PasswordResetAlert, { message: "Your request was successful. Further instructions have been sent to you via email", status: "success" }, void 0) }, void 0) }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ mb: "20px" }, { children: _jsx(Text, __assign({ fontSize: "larger", fontWeight: "semibold", color: "primary.600", textAlign: "center" }, { children: "Reset password" }), void 0) }), void 0), _jsx(Card, { children: _jsx(CardBody, { children: _jsx(PasswordResetForm, { onSuccess: onSuccess }, void 0) }, void 0) }, void 0)] }, void 0)), _jsx(Text, __assign({ mt: "20px", color: "primary.600", textAlign: "center" }, { children: _jsx(Link, __assign({ to: "/login" }, { children: "Back to login" }), void 0) }), void 0)] }, void 0));
}
