var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
export var DashboardMenuCard = function (props) {
    var _a, _b, _c;
    var title = props.title, onClick = props.onClick, isPrimary = props.isPrimary;
    var hasNotification = !!props.notification && !!props.notification.count;
    return (_jsxs(Box, __assign({ bg: isPrimary ? 'primary.500' : 'white', boxShadow: "1px 1px 4px 0px rgba(23, 28, 52, 0.04)", borderRadius: "8px" }, { children: [_jsx(Flex, __assign({ justifyContent: "center", padding: "18px 15px", opacity: onClick ? 1 : 0.5, cursor: onClick ? 'pointer' : 'default', onClick: onClick }, { children: _jsxs(Heading, __assign({ color: isPrimary ? 'white' : 'black', as: "h4", fontSize: "xl", textAlign: "center" }, { children: [title, ' ', hasNotification && (_jsx(Box, { display: "inline-block", w: "8px", h: "8px", borderRadius: "8px", bg: "critical.500", position: "relative", top: "-10px" }, void 0))] }), void 0) }), void 0), hasNotification && (_jsx(Flex, __assign({ p: "6px 10px", m: "0 6px 6px", bg: "success.50", position: "relative", borderRadius: "4px", alignItems: "center", justifyContent: "center", gridGap: "4px", cursor: ((_a = props.notification) === null || _a === void 0 ? void 0 : _a.onClick) ? 'pointer' : 'default', onClick: (_b = props.notification) === null || _b === void 0 ? void 0 : _b.onClick }, { children: _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", textAlign: "center", noOfLines: 2 }, { children: (_c = props.notification) === null || _c === void 0 ? void 0 : _c.message }), void 0) }), void 0))] }), void 0));
};
