var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { formatCurrency } from '@obtainly/utils';
export var PromotionCard = function (_a) {
    var _b, _c;
    var promotion = _a.promotion;
    return (_jsxs("div", { children: [(_b = promotion.promotion_values) === null || _b === void 0 ? void 0 : _b.map(function (promotionValue) { return (_jsx(PromotionItem, { promotionValue: promotionValue }, promotionValue._id)); }), (_c = promotion.promotion_products) === null || _c === void 0 ? void 0 : _c.map(function (promotionProduct) { return (_jsx(PromotionItem, { promotionProduct: promotionProduct }, promotionProduct._id)); })] }, void 0));
};
export var PromotionItem = function (_a) {
    var _b, _c;
    var promotionValue = _a.promotionValue, promotionProduct = _a.promotionProduct;
    if (!promotionProduct && !promotionValue) {
        throw Error('PromotionItem: promotionValue or promotionProduct is required.');
    }
    return (_jsx(Flex, __assign({ gridGap: "6px", my: 2, bg: "white", padding: "15px", border: "1px solid", borderColor: "gray.100", borderRadius: "6px" }, { children: _jsx(Text, { children: promotionValue
                ? "Place an order of ".concat(formatCurrency(promotionValue.trigger_lower_value, { showCurrency: true }), " to ").concat(formatCurrency(promotionValue.trigger_upper_value, {
                    showCurrency: true,
                }), " and earn ").concat(promotionValue.is_percentage
                    ? "".concat(promotionValue.reward, "% of total order")
                    : formatCurrency(promotionValue.reward, {
                        showCurrency: true,
                    }))
                : promotionProduct
                    ? "Get  ".concat(promotionProduct.reward, " ").concat(pluralize('case', promotionProduct.reward), " of ").concat((_b = promotionProduct.reward_product) === null || _b === void 0 ? void 0 : _b.name, " when you buy ").concat(promotionProduct.trigger_value, " ").concat(pluralize('case', promotionProduct.trigger_value), " of ").concat((_c = promotionProduct.product) === null || _c === void 0 ? void 0 : _c.name)
                    : null }, void 0) }), void 0));
};
