var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { AppVersionStatus, useAppVersion, useRouter } from 'src/core/hooks';
import { HeaderMenu } from './HeaderMenu';
import { installationAtom, useAtom } from 'src/core/store';
export var Header = function (_a) {
    var title = _a.title, variant = _a.variant, props = __rest(_a, ["title", "variant"]);
    var router = useRouter();
    var sizes = useTheme().sizes;
    var _b = useAtom(installationAtom)[0], isReady = _b.isReady, isInstalled = _b.isInstalled, installEvent = _b.installEvent;
    var appVersionStatus = useAppVersion().appVersionStatus;
    var hasPrompt = ((isReady && !isInstalled && !!installEvent) ||
        appVersionStatus === AppVersionStatus.OLD) &&
        process.env.NODE_ENV !== 'development';
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ as: "header", top: hasPrompt ? sizes.layout.appPrompt : 0, left: 0, width: "100%", zIndex: 31, position: "fixed" }, { children: _jsx(Box, __assign({ px: "20px", borderRadius: "0 0 10px 10px", borderColor: "gray.100", color: variant === 'default' ? 'white' : '', bg: variant === 'default' ? 'primary.500' : 'rgba(247, 248, 249, 0.7)', boxShadow: variant === 'default'
                        ? '1px 1px 4px 0px rgba(23, 28, 52, 0.04)'
                        : '', backdropFilter: "blur(4px)" }, { children: _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "space-between", h: sizes.layout.pageHeader, gridGap: "15px" }, { children: [_jsxs(Flex, __assign({ alignItems: "center", gridGap: "15px" }, { children: [_jsx(Box, __assign({ cursor: "pointer", onClick: props.goBack || router.goBack }, { children: _jsx(FiArrowLeft, { size: 22 }, void 0) }), void 0), _jsx(Link, __assign({ to: router.getLink('dashboard') }, { children: _jsx(Text, __assign({ fontFamily: "archivo", fontWeight: "semibold", fontSize: "xl", noOfLines: 1 }, { children: title }), void 0) }), void 0)] }), void 0), _jsx(HeaderMenu, {}, void 0)] }), void 0) }), void 0) }), void 0), _jsx(Box, { marginTop: "calc(".concat(sizes.layout.pageHeader, " - 0px)") }, void 0)] }, void 0));
};
Header.defaultProps = {
    variant: 'default',
};
