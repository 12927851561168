var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable prefer-const */
import { Alert, AlertDescription, AlertIcon, Box, Button, Heading, HStack, Modal, ModalBody, ModalContent, ModalOverlay, PinInput, PinInputField, Text, } from '@chakra-ui/react';
import { useMutateModel } from '@obtainly/http';
import { getUnixTime } from 'date-fns';
import { Formik } from 'formik';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useRouter, useToast } from 'src/core/hooks';
import { distributorsAtom, fundingAtom, initialFundingState, userAtom, } from '../store';
import { analytics, Event } from 'src/core/utils';
var OTP_TIMER = 60; // seconds
var USER_TYPE = 'merchant';
export var OtpDialog = function (_a) {
    var isOpen = _a.isOpen, lastRequestedAt = _a.lastRequestedAt, onClose = _a.onClose, onGenerateOtp = _a.onGenerateOtp;
    var _b = useAtom(fundingAtom), _c = _b[0], amount = _c.amount, type = _c.type, interestRate = _c.interestRate, setFundingAtom = _b[1];
    var distributor = useAtom(distributorsAtom)[0].active;
    var user = useAtom(userAtom)[0];
    var router = useRouter();
    var toast = useToast().toast;
    var _d = useState(false), isSubmitting = _d[0], setSubmitting = _d[1];
    var _e = useState(null), counter = _e[0], setCounter = _e[1];
    var formSchema = useMemo(function () {
        return Yup.object({
            hash: Yup.string()
                .matches(/[0-9]{6}/g, 'Invalid OTP')
                .required()
                .label('One Time Password'),
        });
    }, []);
    var formValues = useMemo(function () { return ({
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
        merchant_id: user === null || user === void 0 ? void 0 : user._id,
        amount: amount,
        type: type,
        otp_owner: user === null || user === void 0 ? void 0 : user._id,
        otp_owner_type: USER_TYPE,
    }); }, [amount, type, distributor === null || distributor === void 0 ? void 0 : distributor._id, user === null || user === void 0 ? void 0 : user._id]);
    var initialValues = {
        hash: '',
    };
    function clearState() {
        setFundingAtom(initialFundingState);
    }
    var createFundingRequest = useMutateModel('funding_request.begin');
    var onSubmitOtpForm = function (_a) {
        var hash = _a.hash;
        setSubmitting(true);
        createFundingRequest.mutate(__assign(__assign({}, formValues), { otp: hash, interest: interestRate }), {
            onSuccess: function (data) {
                analytics.track(Event.FundingRequestCreate, {
                    funding_request_id: data,
                });
                clearState();
                toast({
                    description: 'Credit request sent successfully',
                    status: 'success',
                });
                router.go('funding-request', { id: data }, { isNew: 'true' });
            },
            onError: function (error) {
                toast({
                    description: error.message,
                    status: 'error',
                });
                setSubmitting(false);
            },
        });
    };
    useEffect(function () {
        var countClock;
        countClock = setInterval(function () {
            counter !== null &&
                counter > 0 &&
                setCounter(function (prev) { return (prev ? --prev : null); });
        }, 1000);
        return function () { return clearTimeout(countClock); };
    }, [counter]);
    useEffect(function () {
        // continue count down
        // from last time otp was generated
        if (lastRequestedAt) {
            var elapsedSeconds = getUnixTime(new Date()) - lastRequestedAt;
            if (elapsedSeconds < OTP_TIMER) {
                setCounter(OTP_TIMER - elapsedSeconds);
            }
        }
        // auto generate
        if (isOpen && !lastRequestedAt && !counter) {
            onGenerateOtp();
        }
    }, [isOpen, counter, lastRequestedAt]); // eslint-disable-line
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsxs(ModalBody, __assign({ h: "auto", maxH: "auto" }, { children: [_jsx(Heading, __assign({ size: "md", my: "20px", textAlign: "center" }, { children: "Verification Code" }), void 0), _jsx(Text, __assign({ color: "gray.700", textAlign: "center", mb: "20px" }, { children: "Please enter verification code sent to your phone, email and WhatsApp" }), void 0), _jsx(Text, __assign({ textAlign: "center", mb: "6px", fontSize: "14px", fontWeight: "medium" }, { children: "One Time Password (OTP)" }), void 0), _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: onSubmitOtpForm, validateOnMount: true, enableReinitialize: true }, { children: function (_a) {
                                    var formik = __rest(_a, []);
                                    return (_jsxs("form", __assign({ onSubmit: function (e) {
                                            e.preventDefault();
                                            formik.handleSubmit();
                                        } }, { children: [_jsx(HStack, __assign({ align: "center", justify: "center" }, { children: _jsxs(PinInput, __assign({ otp: true, type: "number", value: formik.values.hash, onChange: function (newValue) {
                                                        return formik.setFieldValue('hash', newValue);
                                                    }, isDisabled: isSubmitting }, { children: [_jsx(PinInputField, {}, void 0), _jsx(PinInputField, {}, void 0), _jsx(PinInputField, {}, void 0), _jsx(PinInputField, {}, void 0), _jsx(PinInputField, {}, void 0), _jsx(PinInputField, {}, void 0)] }), void 0) }), void 0), formik.errors && formik.errors.hash && (_jsxs(Alert, __assign({ status: "warning", borderRadius: "6px", alignItems: "start", mt: "0.75rem" }, { children: [_jsx(AlertIcon, {}, void 0), _jsx(AlertDescription, { children: _jsx(Text, { children: formik.errors.hash }, void 0) }, void 0)] }), void 0)), _jsx(Box, __assign({ textAlign: "center", my: "20px" }, { children: _jsx(Button, __assign({ colorScheme: "primary", type: "submit", isDisabled: false, isLoading: isSubmitting }, { children: "Submit" }), void 0) }), void 0)] }), void 0));
                                } }), void 0), _jsxs(Box, __assign({ textAlign: "center", mt: "20px", fontSize: "14px" }, { children: [_jsx(Text, __assign({ color: "gray.700" }, { children: "Didn't receive an OTP?" }), void 0), _jsx(Text, { children: _jsxs(Button, __assign({ variant: "link", colorScheme: "primary", onClick: function () { return onGenerateOtp(); }, isDisabled: !!(counter && counter > 0) || isSubmitting }, { children: ["Resend OTP ", counter ? "in ".concat(counter, "s") : null] }), void 0) }, void 0)] }), void 0)] }), void 0) }, void 0)] }), void 0) }, void 0));
};
