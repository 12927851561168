var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useMutateModel } from '@obtainly/http';
import { useToast } from 'src/core/hooks';
import { analytics, getMessagingToken, onMessageListener } from 'src/core/utils';
import { useAtom, userAtom } from 'src/core/store';
export function usePushNotification() {
    var _this = this;
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var _a = React.useState(''), token = _a[0], setToken = _a[1];
    var createToken = useMutateModel('device_token.create');
    /**
     * Checks if browser supports push notifications
     */
    var isPushSupported = function () {
        if (!('PushManager' in window)) {
            // eslint-disable-next-line
            console.log('Push notification is not supported in your browser.');
            return false;
        }
        return true;
    };
    /**
     * Checks if user has granted permission to push notifications
     */
    var isNotificationAllowed = function () {
        if (!('Notification' in window) || Notification.permission !== 'granted') {
            // eslint-disable-next-line
            console.log('User has not granted permission push notification.');
            return false;
        }
        return true;
    };
    var getToken = function () { return __awaiter(_this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getMessagingToken()];
                case 1:
                    token = _a.sent();
                    if (!token) {
                        return [2 /*return*/, false];
                    }
                    setToken(token);
                    analytics.setUser({ 'Push Token': token || '' });
                    if (token && (user === null || user === void 0 ? void 0 : user.user_id)) {
                        createToken.mutate({
                            token: token,
                            tag: '',
                            status: '',
                            user_id: user.user_id,
                        });
                    }
                    return [2 /*return*/, true];
            }
        });
    }); };
    onMessageListener()
        .then(function (payload) {
        if (!(payload === null || payload === void 0 ? void 0 : payload.notification)) {
            return;
        }
        // console.log('message received', payload)
        if (process.env.REACT_APP_ENV === 'development') {
            toast({
                title: payload.notification.title,
                description: payload.notification.body,
                status: 'warning',
                duration: null,
                isClosable: true,
            });
        }
    })
        .catch(function (error) {
        // eslint-disable-next-line
        console.log('Message receive failed: ', error);
    });
    return { token: token, isPushSupported: isPushSupported, isNotificationAllowed: isNotificationAllowed, getToken: getToken };
}
