var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
export function IframeBox(props) {
    var title = props.title, content = props.content, boxProps = __rest(props, ["title", "content"]);
    var iframeRef = useRef(null);
    var writeHTML = function (data) {
        var frame = iframeRef.current;
        if (!frame) {
            return;
        }
        var doc = frame.contentDocument;
        if (!doc || !frame.contentWindow) {
            return;
        }
        doc.open();
        doc.write(data);
        doc.close();
        frame.style.width = '100%';
        frame.style.height = "".concat(frame.contentWindow.document.body.scrollHeight, "px");
    };
    useEffect(function () {
        writeHTML(content);
        return;
    }, [content]);
    return (_jsx(Box, __assign({}, boxProps, { children: _jsx("iframe", { title: title || 'content', src: "about:blank", ref: iframeRef }, void 0) }), void 0));
}
