var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup } from '@obtainly/ui/web-shared/FormField';
import { PasswordValidator } from '@obtainly/ui/web-shared';
import { useMutateModel } from '@obtainly/http';
import { Grid } from '@chakra-ui/layout';
import { useLogin, useToast } from 'src/core/hooks';
import { analytics, Event } from 'src/core/utils';
export var formSchema = Yup.object({
    hash: Yup.string()
        .nullable()
        .min(8)
        .matches(/[a-zA-Z]/, 'Password must contain one letter (a-z or A-Z)')
        .matches(/[0-9]/, 'Password must contain one number (0-9)')
        .label('Password'),
    hash_confirm: Yup.string()
        .required('Re-type password for confirmation')
        .oneOf([Yup.ref('hash'), null], 'Passwords do not match'),
});
export var PasswordRecoveryForm = function (_a) {
    var recoveryCode = _a.recoveryCode, phone = _a.phone;
    var toast = useToast().toast;
    var doLogin = useLogin();
    var _b = useMutateModel('merchant.recover_forgotten_auth'), isLoading = _b.isLoading, doRecovery = __rest(_b, ["isLoading"]);
    var initialValues = {
        hash: '',
        hash_confirm: '',
    };
    var onSubmit = function (values, _a) {
        var resetForm = _a.resetForm, setSubmitting = _a.setSubmitting;
        if (!recoveryCode || !phone) {
            resetForm();
            setSubmitting(false);
            return;
        }
        var hash = values.hash;
        doRecovery.mutate({ recovery_code: recoveryCode, commit: true, hash: hash }, {
            onSuccess: function () {
                analytics.track(Event.PasswordRecovery);
                toast({ status: 'success', description: 'Password updated' });
                doLogin.mutate({
                    phone: phone,
                    hash: hash,
                    remember: true,
                }, {
                    onSettled: function () {
                        resetForm();
                        setSubmitting(false);
                    },
                });
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
                setSubmitting(false);
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(Grid, __assign({ gridGap: "10px", templateColumns: ['1fr', '1fr 1fr'] }, { children: [_jsxs(FormGroup, __assign({ label: "New Password" }, { children: [_jsx(FormikField, { autoComplete: "off", type: "password", name: "hash", placeholder: "Type password" }, void 0), formik.errors.hash && formik.touched.hash ? (_jsx(PasswordValidator, { password: formik.values.hash || '', validations: {
                                            length: true,
                                            letter: true,
                                            number: true,
                                        } }, void 0)) : null] }), void 0), _jsx(FormGroup, __assign({ label: "Confirm New Password" }, { children: _jsx(FormikField, { autoComplete: "off", type: "password", name: "hash_confirm", placeholder: "Re-type password" }, void 0) }), void 0)] }), void 0), _jsx(Button, __assign({ mt: 2, isFullWidth: true, type: "submit", colorScheme: "primary", isDisabled: isLoading ||
                            formik.isSubmitting ||
                            !formik.dirty ||
                            !formik.isValid, isLoading: isLoading || formik.isSubmitting }, { children: "Update Password" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};
