var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { everyone, Role } from '@obtainly/bootstrap';
import { FundingRequest, FundingRequests, NewFundingRequest } from './pages';
export var fundingRequestPermissions = [
    { id: 'view_funding_requests', roles: __spreadArray([], everyone, true) },
    {
        id: 'begin_funding_request',
        roles: [Role.Merchant, Role.MerchantOfficer],
    },
    { id: 'approve_funding_request', roles: [Role.SupliasAdmin] },
    { id: 'activate_funding_request', roles: [Role.SupliasAdmin] },
    { id: 'reject_funding_request', roles: [Role.SupliasAdmin] }, // move to "rejected"
];
export var fundingRequestRoutes = [
    {
        id: 'funding-requests',
        path: '/funding-requests',
        component: FundingRequests,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'funding-request',
        path: '/funding-requests/:id',
        component: FundingRequest,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'new-funding-request',
        path: '/new-funding-request',
        component: NewFundingRequest,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
