var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Icon, Text } from '@chakra-ui/react';
import { RiLockLine } from 'react-icons/ri';
export var PoweredBadge = function () {
    return (_jsxs(Flex, __assign({ gap: "6px", alignItems: "center" }, { children: [_jsx(Icon, { as: RiLockLine, color: "gray.400", fontSize: "lg" }, void 0), _jsxs(Text, __assign({ fontSize: "sm", color: "gray.600", textTransform: "capitalize" }, { children: ["Powered by", ' ', _jsx(Text, __assign({ as: "span", color: "body" }, { children: "BESTSTAR MFB" }), void 0)] }), void 0)] }), void 0));
};
