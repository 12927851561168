var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, Text, VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Form, Formik } from 'formik';
import ProductListBuilder from 'src/product/components/ProductListBuilder';
import { Autocomplete, Card, CardBody, CardFooter, CardHeader, FormGroup, FormikField, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { ComplaintMetaTag, ComplaintTag, } from '@obtainly/models';
import { useMutateModel } from '@obtainly/http';
import { useAtom, userAtom, distributorsAtom } from 'src/core/store';
import * as Yup from 'yup';
var productsSchema = Yup.array()
    .of(Yup.object().shape({
    product: Yup.mixed().required('No product selected'),
    quantity: Yup.number()
        .min(1, 'Product quantity must be 1 or more')
        .required(),
}))
    .min(1, 'Select 1 or more products')
    .required('Product is required');
var complaintFormSchema = Yup.object({
    status: Yup.string().required('Complaint status is required.'),
    merchant_id: Yup.string().required('Customer is required.'),
    distributor_id: Yup.string().required('Distributor is required.'),
    products: Yup.array()
        .when('tag', {
        is: ComplaintTag.Damages,
        then: productsSchema,
    })
        .when('tag', {
        is: ComplaintTag.Shortages,
        then: productsSchema,
    })
        .when('tag', {
        is: ComplaintTag.Expiry,
        then: productsSchema,
    }),
    tag: Yup.string().required('Tag is required.'),
    note: Yup.string()
        .when('tag', {
        is: ComplaintTag.General,
        then: Yup.string()
            .min(10, 'Enter at least 10 characters')
            .required('Note is required'),
    })
        .notRequired(),
});
var tagOptions = __spreadArray([
    { label: '---', value: '' }
], Object.entries(ComplaintTag).map(function (_a) {
    var key = _a[0], value = _a[1];
    return { label: key, value: value };
}), true);
var ComplaintForm = function (props) {
    var action = props.action, complaint = props.complaint;
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var buttonSize = useBreakpointValue(['sm', 'sm']);
    var _a = React.useState(props.sequenceData || []), sequenceQueue = _a[0], setSequenceQueue = _a[1];
    var createComplaint = useMutateModel('complaint.begin');
    var updateComplaint = useMutateModel('complaint.update');
    var upsertComplaintMeta = useMutateModel('complaint_meta.upsert_many');
    var initialValues = React.useMemo(function () {
        var _a, _b;
        var init = {
            ref: (complaint === null || complaint === void 0 ? void 0 : complaint.ref) || '',
            status: (complaint === null || complaint === void 0 ? void 0 : complaint.status) || 'open',
            merchant_id: (complaint === null || complaint === void 0 ? void 0 : complaint.merchant_id) || (user === null || user === void 0 ? void 0 : user._id) || '',
            distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
            tag: (complaint === null || complaint === void 0 ? void 0 : complaint.tag) || ComplaintTag.Shortages,
            products: [],
            note: ((_b = (_a = complaint === null || complaint === void 0 ? void 0 : complaint.complaint_meta) === null || _a === void 0 ? void 0 : _a.find(function (meta) { return meta.tag === ComplaintMetaTag.Note; })) === null || _b === void 0 ? void 0 : _b.value) || '',
        };
        return sequenceQueue.length
            ? __assign(__assign({}, init), { tag: sequenceQueue[0].tag, products: [], note: '' }) : init;
    }, [complaint, distributor, sequenceQueue, user]);
    var lastInQueue = React.useMemo(function () {
        return !(sequenceQueue.length > 1);
    }, [sequenceQueue]);
    var nextSequence = function () {
        if (!lastInQueue) {
            setSequenceQueue(function (prev) {
                return prev.slice(1);
            });
        }
        else {
            props.onSuccess();
        }
    };
    var doUpsert = function (payload, callback) {
        upsertComplaintMeta.mutate(payload, {
            onSuccess: function () {
                callback === null || callback === void 0 ? void 0 : callback();
                sequenceQueue.length ? nextSequence() : props.onSuccess();
                toast({ status: 'success', description: 'Complaint created' });
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    var onSubmit = function (values, actions) {
        var mutableValues = __assign({}, values);
        delete mutableValues.note;
        delete mutableValues.products;
        var payload = {
            complaint_id: (complaint === null || complaint === void 0 ? void 0 : complaint._id) || '',
            tags: [],
            metas: [],
            values: [],
        };
        if (values.tag === ComplaintTag.General) {
            payload.values.push(values.note);
            payload.metas.push('{}');
            payload.tags.push(ComplaintMetaTag.Note);
        }
        else {
            values.products.forEach(function (item) {
                payload.values.push(item.product._id);
                payload.metas.push(JSON.stringify({ quantity: item.quantity, price: item.product.price }));
                payload.tags.push(ComplaintMetaTag.Product);
            });
        }
        if (action === 'create') {
            mutableValues.ref = 'begin';
            createComplaint.mutate(__assign({}, mutableValues), {
                onSuccess: function (data) {
                    payload.complaint_id = data;
                    doUpsert(payload, function () {
                        actions.resetForm();
                    });
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateComplaint.mutate(__assign(__assign({}, mutableValues), { _id: complaint === null || complaint === void 0 ? void 0 : complaint._id }), {
                onSuccess: function () {
                    doUpsert(payload, function () {
                        actions.resetForm();
                    });
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsx(Formik, __assign({ initialValues: initialValues, validationSchema: complaintFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
            var _b;
            var formik = __rest(_a, []);
            var ActionButtons = function () { return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [!sequenceQueue.length ? (_jsx(Button, __assign({ size: buttonSize, variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0)) : (_jsx(Button, __assign({ size: buttonSize, variant: lastInQueue ? 'solid' : 'outline', colorScheme: lastInQueue ? 'success' : 'gray', mr: 3, onClick: nextSequence, isLoading: createComplaint.isLoading ||
                            updateComplaint.isLoading ||
                            props.isUpdatingOrderTag, isDisabled: createComplaint.isLoading ||
                            updateComplaint.isLoading ||
                            upsertComplaintMeta.isLoading ||
                            props.isUpdatingOrderTag }, { children: lastInQueue
                            ? 'Confirm Delivery'
                            : "No ".concat(sequenceQueue[0].tag) }), void 0)), _jsx(Button, __assign({ size: buttonSize, type: "submit", colorScheme: "primary", isLoading: createComplaint.isLoading ||
                            updateComplaint.isLoading ||
                            props.isUpdatingOrderTag, isDisabled: createComplaint.isLoading ||
                            updateComplaint.isLoading ||
                            upsertComplaintMeta.isLoading ||
                            !formik.dirty ||
                            !formik.isValid ||
                            props.isUpdatingOrderTag, onClick: function () { return onSubmit(formik.values, formik); } }, { children: !sequenceQueue.length
                            ? "".concat(action === 'create' ? 'Add' : 'Update', " Complaint")
                            : "Report ".concat(sequenceQueue[0].tag) }), void 0)] }), void 0)); };
            return (_jsxs(_Fragment, { children: [_jsx(Text, { children: sequenceQueue.length
                            ? "Confirm Order ".concat(props.order ? '- #' + props.order.ref : '')
                            : action === 'create'
                                ? 'New Complaint'
                                : 'Update Complaint' }, void 0), _jsx(ActionButtons, {}, void 0), _jsxs(Card, { children: [!!sequenceQueue.length && (_jsx(CardHeader, { heading: "Report ".concat(sequenceQueue[0].tag) }, void 0)), _jsx(CardBody, { children: _jsx(Form, __assign({ onSubmit: formik.handleSubmit }, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [!sequenceQueue.length && (_jsx(FormGroup, __assign({ label: "Complaint Type" }, { children: _jsx(Autocomplete, { size: "sm", value: formik.values.tag, onChange: function (_a) {
                                                        var value = _a.value;
                                                        formik.setFieldValue('tag', value);
                                                    }, options: tagOptions }, void 0) }), void 0)), formik.values.tag === 'general' ? (_jsx(FormGroup, __assign({ label: "Complaint Note" }, { children: _jsx(FormikField, { size: "sm", name: "note", placeholder: "Type here...", as: "textarea" }, void 0) }), void 0)) : (_jsx(FormGroup, __assign({ label: "Products", columns: [3, 1] }, { children: _jsx(ProductListBuilder
                                                // value={formik.values.products}
                                                , { 
                                                    // value={formik.values.products}
                                                    onUpdate: function (productList) {
                                                        formik.setFieldValue('products', productList);
                                                    }, presetProducts: (sequenceQueue.length &&
                                                        ((_b = sequenceQueue[0]) === null || _b === void 0 ? void 0 : _b.products)) ||
                                                        [] }, void 0) }), void 0))] }), void 0) }), void 0) }, void 0), _jsx(CardFooter, { children: _jsx(ActionButtons, {}, void 0) }, void 0)] }, void 0)] }, void 0));
        } }), void 0));
};
export default ComplaintForm;
