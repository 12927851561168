var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { VStack, Box, Text, Flex, Divider } from '@chakra-ui/layout';
import { formatCurrency } from '@obtainly/utils';
export var OrderInvoice = function (_a) {
    var order = _a.order, hideSummary = _a.hideSummary;
    var orderItems = React.useMemo(function () {
        return (order === null || order === void 0 ? void 0 : order.items) ? Object.values(order.items) : [];
    }, [order === null || order === void 0 ? void 0 : order.items]);
    return (_jsx(_Fragment, { children: _jsx(VStack, __assign({ fontSize: "14px", spacing: 2, align: "stretch" }, { children: (orderItems === null || orderItems === void 0 ? void 0 : orderItems.length) ? (_jsxs(_Fragment, { children: [_jsx(VStack, __assign({ spacing: 0, alignItems: "stretch" }, { children: orderItems.map(function (orderItem, index) {
                            var _a, _b;
                            return (_jsxs(Flex, __assign({ gridGap: 2, py: 1, borderBottom: index + 1 !== orderItems.length ? '1px solid' : '0', borderColor: "gray.100", justifyContent: "space-between" }, { children: [_jsxs(Box, __assign({ width: "50%" }, { children: [_jsxs(Text, { children: [(_a = orderItem.product) === null || _a === void 0 ? void 0 : _a.name, " "] }, void 0), orderItem.free_item ? (_jsxs(Text, __assign({ color: "gray.700" }, { children: ["Free: ", (_b = orderItem.free_item.product) === null || _b === void 0 ? void 0 : _b.name, _jsxs(Text, __assign({ as: "span", fontWeight: "medium" }, { children: [' ', _jsxs("b", { children: ["x ", orderItem.free_item.quantity] }, void 0)] }), void 0)] }), void 0)) : null] }), void 0), _jsx(Text, __assign({ width: "15%", align: "right", fontWeight: "medium" }, { children: orderItem.quantity }), void 0), _jsx(Text, __assign({ width: "35%", align: "right", fontWeight: "medium" }, { children: formatCurrency(orderItem.amount, {
                                            showCurrency: true,
                                        }) }), void 0)] }), orderItem._id));
                        }) }), void 0), !hideSummary && (_jsxs(_Fragment, { children: [_jsx(Divider, { border: "1px solid", borderColor: "gray.100" }, void 0), _jsxs(VStack, __assign({ spacing: 1, alignItems: "stretch" }, { children: [!!(order === null || order === void 0 ? void 0 : order.subtotal) && (_jsxs(Flex, __assign({ color: "gray.600", justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ width: "60%" }, { children: "Sub total" }), void 0), _jsx(Text, { children: formatCurrency((order === null || order === void 0 ? void 0 : order.subtotal) || 0, {
                                                    showCurrency: true,
                                                }) }, void 0)] }), void 0)), !!(order === null || order === void 0 ? void 0 : order.discount) && (_jsxs(Flex, __assign({ color: "gray.600", justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ width: "60%" }, { children: "Discount" }), void 0), _jsxs(Text, { children: [order.discount.toFixed(2), "%"] }, void 0)] }), void 0)), !!(order === null || order === void 0 ? void 0 : order.deduction) && (_jsxs(Flex, __assign({ color: "gray.600", justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ width: "60%" }, { children: "Deduction" }), void 0), _jsx(Text, { children: formatCurrency(order.deduction, {
                                                    showCurrency: true,
                                                }) }, void 0)] }), void 0)), _jsxs(Flex, __assign({ justifyContent: "space-between" }, { children: [_jsx(Text, __assign({ width: "60%", fontWeight: "semibold" }, { children: "Total" }), void 0), _jsx(Text, __assign({ fontWeight: "bold" }, { children: formatCurrency((order === null || order === void 0 ? void 0 : order.amount) || 0, {
                                                    showCurrency: true,
                                                }) }), void 0)] }), void 0)] }), void 0)] }, void 0))] }, void 0)) : null }), void 0) }, void 0));
};
