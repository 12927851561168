var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FiActivity, FiBox, FiCreditCard } from 'react-icons/fi';
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { OrderPaymentStatus, OrderTag, OrderType } from '@obtainly/models';
export var OrderTagBadge = function (_a) {
    var tag = _a.tag, type = _a.type, rest = __rest(_a, ["tag", "type"]);
    var colorScheme = tag === OrderTag.Draft
        ? 'orange'
        : tag === OrderTag.Pending
            ? 'warning'
            : tag === OrderTag.Delivered
                ? 'success'
                : tag === OrderTag.Cancelled
                    ? 'critical'
                    : 'gray';
    return (_jsxs(Flex, __assign({ gridGap: 1 }, { children: [_jsx(Badge, __assign({ display: "flex", alignItems: "center", colorScheme: colorScheme }, rest, { children: _jsx(FiActivity, {}, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "".concat(colorScheme, ".700"), textTransform: "capitalize" }, { children: tag === OrderTag.InTransit && type === OrderType.Pickup
                    ? 'Ready for pickup'
                    : tag === OrderTag.InTransit
                        ? 'In Transit'
                        : tag }), void 0)] }), void 0));
};
export var OrderPaymentBadge = function (_a) {
    var status = _a.status, rest = __rest(_a, ["status"]);
    var colorScheme = status === OrderPaymentStatus.Paid ? 'success' : 'critical';
    return (_jsxs(Flex, __assign({ gridGap: 1 }, { children: [_jsx(Badge, __assign({ display: "flex", alignItems: "center", colorScheme: colorScheme }, rest, { children: _jsx(FiCreditCard, {}, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "".concat(colorScheme, ".700"), textTransform: "capitalize" }, { children: status }), void 0)] }), void 0));
};
export var OrderTypeBadge = function (_a) {
    var type = _a.type, rest = __rest(_a, ["type"]);
    var colorScheme = type === OrderType.Delivery ? 'blue' : 'orange';
    return (_jsxs(Flex, __assign({ gridGap: 1 }, { children: [_jsx(Badge, __assign({ display: "flex", alignItems: "center", colorScheme: colorScheme }, rest, { children: _jsx(FiBox, {}, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "".concat(colorScheme, ".700"), textTransform: "capitalize" }, { children: type }), void 0)] }), void 0));
};
