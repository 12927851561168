var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useQueryParams } from '@obtainly/hooks';
import React from 'react';
import { Layout, Navigation } from 'src/core/components';
import { dashboardAtom, useAtom } from 'src/core/store';
import { DashboardHeader, DashboardMenu, DashboardStats, PublicInfo, PushNotificationRequestModal, } from 'src/dashboard/components';
import { MerchantProfileCTA, VirtualAccountModal } from 'src/merchant';
import { SecondAccountModal } from 'src/merchant/components/SecondAccountModal';
import { LaunchBanner } from '../components/LaunchBanner';
import OverdueBanner from '../components/OverdueBanner';
export var Dashboard = function () {
    var _a, _b;
    var dashboard = useAtom(dashboardAtom)[0];
    var showAccountDetails = useQueryParams().show_account_details;
    var _c = React.useState(false), virtualAccount = _c[0], setVirtualAccount = _c[1];
    var _d = React.useState(false), secondAccount = _d[0], setSecondAccount = _d[1];
    React.useEffect(function () {
        var _a, _b;
        if (showAccountDetails &&
            ((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.data) === null || _a === void 0 ? void 0 : _a.balance) < ((_b = dashboard === null || dashboard === void 0 ? void 0 : dashboard.data) === null || _b === void 0 ? void 0 : _b.order_value_pending)) {
            setVirtualAccount(true);
        }
    }, [showAccountDetails, dashboard]);
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, __assign({ p: "0px", mt: "0px", hideNavigation: true, as: Box, maxW: "auto" }, { children: [_jsx(DashboardHeader, {}, void 0), _jsx(DashboardStats, { data: dashboard === null || dashboard === void 0 ? void 0 : dashboard.data, isLoading: (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.isLoading) !== null && _a !== void 0 ? _a : true }, void 0), _jsx(LaunchBanner, {}, void 0), _jsx(OverdueBanner, {}, void 0), _jsx(MerchantProfileCTA, {}, void 0), _jsx(PublicInfo, {}, void 0), _jsx(DashboardMenu, { data: dashboard, isLoading: (_b = dashboard === null || dashboard === void 0 ? void 0 : dashboard.isLoading) !== null && _b !== void 0 ? _b : true, showAccountDetails: function () { return setVirtualAccount(true); }, showSecondAccount: function () { return setSecondAccount(true); } }, void 0), _jsx(Navigation, {}, void 0)] }), void 0), _jsx(VirtualAccountModal, { isOpen: virtualAccount, onClose: function () { return setVirtualAccount(false); } }, void 0), _jsx(SecondAccountModal, { isOpen: secondAccount, onClose: function () { return setSecondAccount(false); } }, void 0), _jsx(PushNotificationRequestModal, {}, void 0)] }, void 0));
};
