var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { PasswordForm, ProfileForm } from '../components';
import { useLogout } from 'src/core/hooks';
import { useAtom, configAtom } from 'src/core/store';
import { Layout } from 'src/core/components';
import { Card, CardBody, CardHeader } from '@obtainly/ui/web-shared';
import { Link as RouterLink } from 'react-router-dom';
import { RiLink } from 'react-icons/ri';
export var Settings = function () {
    var doLogout = useLogout();
    var _a = useAtom(configAtom)[0], appEnv = _a.appEnv, appVersion = _a.appVersion;
    return (_jsx(Layout, __assign({ headerProps: { title: 'Settings' } }, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsxs(Card, { children: [_jsx(CardHeader, { heading: "Personal Profile" }, void 0), _jsx(CardBody, { children: _jsx(ProfileForm, {}, void 0) }, void 0)] }, void 0), _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Business Profile", description: "Update or view KYC documents" }, void 0), _jsx(CardBody, { children: _jsx(RouterLink, __assign({ to: "/profile" }, { children: _jsxs(Flex, __assign({ color: "primary.600", gap: "4px", align: "center" }, { children: [_jsx(RiLink, {}, void 0), _jsx(Link, { children: "View Business profile" }, void 0)] }), void 0) }), void 0) }, void 0)] }, void 0), _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Update Password" }, void 0), _jsx(CardBody, { children: _jsx(PasswordForm, {}, void 0) }, void 0)] }, void 0), _jsx(Button, __assign({ colorScheme: "critical", onClick: function () { return doLogout.mutate(); }, isLoading: doLogout.isLoading, isDisabled: doLogout.isLoading }, { children: "Logout" }), void 0), _jsx(Box, { children: _jsxs(Text, __assign({ textAlign: "center", fontSize: "sm", color: "gray.700", textTransform: "capitalize" }, { children: ["Version", ' ', _jsx(Text, __assign({ as: "span", fontFamily: "mono" }, { children: appVersion }), void 0), ' ', "(", appEnv, ")"] }), void 0) }, void 0)] }), void 0) }), void 0));
};
