var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/layout';
import { Card, CardBody, CardHeader } from 'src/core/components';
import { formatUnixTime } from '@obtainly/utils';
import { ComplaintStatusBadge, ComplaintTagBadge } from './ComplaintBadge';
export var ComplaintDetails = function (_a) {
    var complaint = _a.complaint;
    var details = React.useMemo(function () {
        var _a;
        return [
            {
                label: 'Complaint Type',
                value: _jsx(ComplaintTagBadge, { value: complaint === null || complaint === void 0 ? void 0 : complaint.tag }, void 0),
            },
            { label: 'Customer', value: (_a = complaint === null || complaint === void 0 ? void 0 : complaint.merchant) === null || _a === void 0 ? void 0 : _a.business_name },
            {
                label: 'Status',
                value: _jsx(ComplaintStatusBadge, { value: complaint === null || complaint === void 0 ? void 0 : complaint.status }, void 0),
            },
            { label: 'Created', value: formatUnixTime((complaint === null || complaint === void 0 ? void 0 : complaint.created) || 0) },
        ];
    }, [complaint]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { heading: "".concat(complaint === null || complaint === void 0 ? void 0 : complaint.tag, " Complaint Details"), textTransform: "capitalize", border: 0 }, void 0), _jsx(CardBody, __assign({ pt: 2 }, { children: _jsx(SimpleGrid, __assign({ columns: 2, mt: 3, spacing: 3, alignItems: "stretch" }, { children: details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", fontWeight: "medium" }, { children: detail.label }), void 0), typeof detail.value === 'string' ? (_jsx(Text, __assign({ textTransform: "capitalize" }, { children: detail.value }), void 0)) : (detail.value)] }, detail.label)); }) }), void 0) }), void 0)] }, void 0));
};
