var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { FundingRequestLogTag, } from '@obtainly/models';
import { ContentLoader, IframeBox } from '@obtainly/ui/web-shared';
import { trimHtmlTemplate } from '@obtainly/utils';
import { useMemo } from 'react';
export var FundingAgreement = function (_a) {
    var _b, _c;
    var fundingRequest = _a.fundingRequest, boxProps = __rest(_a, ["fundingRequest"]);
    var embeddedAgreement = (_c = (_b = fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest.logs) === null || _b === void 0 ? void 0 : _b[FundingRequestLogTag.LenderAgreement]) === null || _c === void 0 ? void 0 : _c[0].value;
    var _d = useQueryModel('funding_request_log.read_by_funding_request_tag', {
        funding_request_id: fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest._id,
        tag: 'lender_agreement',
    }, { enabled: !!(fundingRequest === null || fundingRequest === void 0 ? void 0 : fundingRequest._id) && !embeddedAgreement }), agreementContent = _d.data, isLoading = _d.isLoading;
    var filteredContent = useMemo(function () {
        if (embeddedAgreement) {
            return trimHtmlTemplate(embeddedAgreement);
        }
        return trimHtmlTemplate((agreementContent === null || agreementContent === void 0 ? void 0 : agreementContent.length) ? agreementContent[0].value : '');
    }, [agreementContent, embeddedAgreement]);
    return (_jsxs(Box, __assign({}, boxProps, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !filteredContent, errorMessage: "Unable to load content" }, void 0), !isLoading && !!filteredContent ? (_jsx(IframeBox, { content: filteredContent }, void 0)) : null] }), void 0));
};
