import { atom, useAtom as useAtomNative } from 'jotai';
import { getUnixTime } from 'date-fns';
import { getSessionToken } from '@obtainly/http';
export var useAtom = useAtomNative;
export var userAtom = atom(null);
export var permissionsAtom = atom([]);
export var userIdAtom = atom(null);
export var authAtom = atom({
    token: getSessionToken(),
    isLoading: false,
    refreshedAt: null,
    activeDate: getUnixTime(new Date()),
});
