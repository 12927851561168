var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MerchantVerification, MerchantVerificationStatus, } from '@obtainly/ui/web-shared';
import React from 'react';
import { Layout } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { profileAtom, useAtom, userAtom } from 'src/core/store';
export var Profile = function () {
    var user = useAtom(userAtom)[0];
    var profileState = useAtom(profileAtom)[0];
    var router = useRouter();
    var goBack = function () { return router.go('/settings'); };
    return (_jsx(Layout, __assign({ headerProps: { title: 'Business Profile', goBack: goBack } }, { children: (user === null || user === void 0 ? void 0 : user._id) ? (_jsxs(_Fragment, { children: [_jsx(MerchantVerificationStatus, { merchantId: user._id }, void 0), _jsx(MerchantVerification, { merchantId: user._id, onChange: profileState.refetchDocuments }, void 0)] }, void 0)) : null }), void 0));
};
