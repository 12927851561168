import { Mixpanel } from '@obtainly/analytics';
export var Event;
(function (Event) {
    Event["AppUpdate"] = "App Update";
    Event["AppInstall"] = "App Install";
    Event["Login"] = "Login";
    Event["Logout"] = "Logout";
    Event["InvalidateToken"] = "Invalidate Token";
    Event["OrderBegin"] = "Order Begin";
    Event["OrderPlacement"] = "Order Placement";
    Event["OrderUpdate"] = "Order Update";
    Event["ProfileUpdate"] = "Profile Update";
    Event["PasswordChange"] = "Password Change";
    Event["PasswordReset"] = "Password Reset";
    Event["PasswordRecovery"] = "Password Recovery";
    Event["NotificationAllow"] = "Notification Allow";
    Event["FundingRequestCreate"] = "Credit Request";
})(Event || (Event = {}));
var MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || '';
export var analytics = new Mixpanel(MIXPANEL_TOKEN);
