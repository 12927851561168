var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { AlertDialog, AlertDialogBody, AlertDialogHeader, AlertDialogFooter, AlertDialogContent, AlertDialogOverlay, } from '@chakra-ui/react';
export var OrderActions = function (_a) {
    var _b;
    var order = _a.order, loadingOrder = _a.loadingOrder, refetchingOrder = _a.refetchingOrder, orderStates = _a.orderStates;
    var buttonSize = useBreakpointValue(['sm', 'sm']);
    var _c = React.useState(), confirm = _c[0], setConfirm = _c[1];
    var cancelRef = React.useRef(null);
    var onCancel = function () {
        setConfirm(null);
    };
    var onConfirm = function () {
        var _a, _b;
        (_b = (_a = confirm === null || confirm === void 0 ? void 0 : confirm.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a, confirm.event);
        setConfirm(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ gridGap: 2 }, { children: (order === null || order === void 0 ? void 0 : order.tag) && orderStates
                    ? orderStates[order.tag]().nextStates.map(function (state, index) {
                        var _a, _b, _c;
                        return (_jsx(React.Fragment, { children: (state === null || state === void 0 ? void 0 : state.userCan) && (_jsx(_Fragment, { children: _jsx(Button, __assign({ size: buttonSize, colorScheme: (_a = state.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.colorScheme, isLoading: (_b = state.triggerButtonProps) === null || _b === void 0 ? void 0 : _b.isLoading, onClick: function (event) {
                                        var _a, _b;
                                        if (state.triggerConfirmation) {
                                            setConfirm({
                                                event: event,
                                                title: state.title || 'Yes, Proceed',
                                                question: state.triggerConfirmation,
                                                triggerButtonProps: state.triggerButtonProps,
                                            });
                                        }
                                        else {
                                            (_b = (_a = state.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a, event);
                                        }
                                    }, isDisabled: loadingOrder ||
                                        refetchingOrder ||
                                        ((_c = state.triggerButtonProps) === null || _c === void 0 ? void 0 : _c.isDisabled), isFullWidth: true }, { children: state === null || state === void 0 ? void 0 : state.title }), void 0) }, void 0)) }, index));
                    })
                    : null }), void 0), _jsx(AlertDialog, __assign({ isOpen: !!confirm, leastDestructiveRef: cancelRef, onClose: onCancel }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsxs(AlertDialogHeader, { children: [confirm === null || confirm === void 0 ? void 0 : confirm.title, "?"] }, void 0), _jsx(AlertDialogBody, { children: confirm === null || confirm === void 0 ? void 0 : confirm.question }, void 0), _jsxs(AlertDialogFooter, { children: [_jsx(Button, __assign({ ref: cancelRef, size: "sm", onClick: onCancel }, { children: "No" }), void 0), _jsxs(Button, __assign({ size: "sm", colorScheme: (_b = confirm === null || confirm === void 0 ? void 0 : confirm.triggerButtonProps) === null || _b === void 0 ? void 0 : _b.colorScheme, onClick: onConfirm, ml: 3 }, { children: ["Yes, ", confirm === null || confirm === void 0 ? void 0 : confirm.title] }), void 0)] }, void 0)] }, void 0) }, void 0) }), void 0)] }, void 0));
};
