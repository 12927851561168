var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { RiCheckboxCircleFill, RiInformationFill } from 'react-icons/ri';
import { formatCurrency } from '@obtainly/utils';
import { ContentLoader } from '../web-shared/ContentLoader';
export var OrderPromotions = function (_a) {
    var _b, _c, _d, _e;
    var order = _a.order, showHeading = _a.showHeading;
    var totalCount = (((_b = order.promotion_values) === null || _b === void 0 ? void 0 : _b.length) || 0) +
        (((_c = order.promotion_products) === null || _c === void 0 ? void 0 : _c.length) || 0);
    var hasPromotion = totalCount > 0;
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !hasPromotion, errorMessage: "No promotions" }, void 0), hasPromotion && showHeading ? (_jsx(Text, __assign({ fontWeight: "semibold", fontSize: "lg" }, { children: pluralize('Promotion', totalCount) }), void 0)) : null, (_d = order.promotion_values) === null || _d === void 0 ? void 0 : _d.map(function (promotionValue) { return (_jsx(OrderPromotionItem, { order: order, promotionValue: promotionValue }, promotionValue._id)); }), (_e = order.promotion_products) === null || _e === void 0 ? void 0 : _e.map(function (promotionProduct) { return (_jsx(OrderPromotionItem, { order: order, promotionProduct: promotionProduct }, promotionProduct._id)); })] }, void 0));
};
export var OrderPromotionItem = function (_a) {
    var _b, _c, _d;
    var order = _a.order, promotionValue = _a.promotionValue, promotionProduct = _a.promotionProduct, context = _a.context;
    if (!promotionProduct && !promotionValue) {
        throw Error('OrderPromotionItem: promotionValue or promotionProduct is required.');
    }
    var applied = useMemo(function () {
        var _a;
        if (!promotionValue && !promotionProduct) {
            return false;
        }
        if (promotionValue) {
            return (order.amount >= promotionValue.trigger_lower_value &&
                order.amount <= promotionValue.trigger_upper_value);
        }
        if (promotionProduct) {
            return (((_a = order.items[promotionProduct.product_id]) === null || _a === void 0 ? void 0 : _a.quantity) >=
                promotionProduct.trigger_value);
        }
    }, [promotionValue, promotionProduct, order.amount, order.items]);
    return (_jsxs(Flex, __assign({ gridGap: "6px", my: 2, bg: context === 'OrderItem' ? 'gray.50' : 'white', padding: context === 'OrderItem' ? '4px' : '0', border: context === 'OrderItem' ? '1px solid' : 'none', borderColor: "gray.100", borderRadius: "6px", opacity: applied ? 0.6 : 1, color: "body", fontSize: context === 'OrderItem' ? 'sm' : 'md' }, { children: [_jsx(Box, __assign({ color: applied ? 'success.600' : 'gray.400', fontSize: context === 'OrderItem' ? 'sm' : 'md', pt: context === 'OrderItem' ? '2px' : '3px' }, { children: applied ? _jsx(RiCheckboxCircleFill, {}, void 0) : _jsx(RiInformationFill, {}, void 0) }), void 0), _jsxs(Text, { children: [promotionValue
                        ? "Place an order of ".concat(formatCurrency(promotionValue.trigger_lower_value, { showCurrency: true }), " to ").concat(formatCurrency(promotionValue.trigger_upper_value, {
                            showCurrency: true,
                        }), " and earn ").concat(promotionValue.is_percentage
                            ? "".concat(promotionValue.reward, "% of total order")
                            : formatCurrency(promotionValue.reward, {
                                showCurrency: true,
                            }))
                        : promotionProduct
                            ? context === 'OrderItem'
                                ? "Buy ".concat(promotionProduct.trigger_value, " ").concat(pluralize('case', promotionProduct.trigger_value), ", get  ").concat(promotionProduct.reward, " ").concat(pluralize('case', promotionProduct.reward), " of ").concat((_b = promotionProduct.reward_product) === null || _b === void 0 ? void 0 : _b.name)
                                : "Get  ".concat(promotionProduct.reward, " ").concat(pluralize('case', promotionProduct.reward), " of ").concat((_c = promotionProduct.reward_product) === null || _c === void 0 ? void 0 : _c.name, " when you buy ").concat(promotionProduct.trigger_value, " ").concat(pluralize('case', promotionProduct.trigger_value), " of ").concat((_d = promotionProduct.product) === null || _d === void 0 ? void 0 : _d.name)
                            : null, applied && (_jsxs(Text, __assign({ as: "span", color: "success.600", fontWeight: "semibold", textTransform: "uppercase" }, { children: [' ', "[Applied]"] }), void 0))] }, void 0)] }), void 0));
};
