import React from 'react';
import { OrderTag } from '@obtainly/models';
import { useQueryModel } from '@obtainly/http';
export function useOrder(orderId) {
    var _a = useQueryModel('order.read', { _id: orderId }, { enabled: !!orderId && orderId !== 'new' }), orders = _a.data, isLoading = _a.isLoading, refetch = _a.refetch, isRefetching = _a.isRefetching;
    var order = React.useMemo(function () { return orders === null || orders === void 0 ? void 0 : orders[0]; }, [orders]);
    var editableOrder = React.useMemo(function () {
        var _editable = true;
        var nonEditableStates = [
            OrderTag.Processing,
            OrderTag.InTransit,
            OrderTag.Delivered,
            OrderTag.Cancelled,
        ];
        if (nonEditableStates.includes(order === null || order === void 0 ? void 0 : order.tag)) {
            return false;
        }
        return _editable;
    }, [order === null || order === void 0 ? void 0 : order.tag]); // eslint-disable-line
    return {
        order: order,
        loadingOrder: isLoading,
        refetchOrder: refetch,
        refetchingOrder: isRefetching,
        editableOrder: editableOrder,
    };
}
