import { Login, Profile, ResetPassword, ResetPasswordWithCode, Settings, UpdatePassword, } from './pages';
export var userRoutes = [
    {
        id: 'profile',
        path: '/profile',
        component: Profile,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'settings',
        path: '/settings',
        component: Settings,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'login',
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        id: 'update_password',
        path: '/update_password',
        component: UpdatePassword,
        exact: true,
        requiresAuth: true,
    },
    {
        id: 'reset_password',
        path: '/reset_password',
        component: ResetPassword,
        exact: true,
    },
    {
        id: 'reset_password_with_code',
        path: '/reset_password/:code',
        component: ResetPasswordWithCode,
        exact: true,
    },
];
export * from './components';
