var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/react';
import { Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/stat';
import pluralize from 'pluralize';
import React from 'react';
import { useCreditLimit, useOrderConfig } from '@obtainly/hooks';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { distributorsAtom, useAtom, userAtom } from 'src/core/store';
export var DashboardStats = function (props) {
    var router = useRouter();
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var data = props.data, loadingStats = props.isLoading;
    var _a = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), user === null || user === void 0 ? void 0 : user._id), usedCredit = _a.usedCredit, loadingCreditLimit = _a.isLoading;
    var _b = useOrderConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id, user === null || user === void 0 ? void 0 : user._id), loadingOrderStatus = _b.isLoading, isOrderActivated = _b.isOrderActivated;
    var isLoading = loadingStats || loadingCreditLimit || loadingOrderStatus;
    var hasUsedCredit = usedCredit !== null;
    var showDashboardStats = !isLoading && (hasUsedCredit || isOrderActivated || (data === null || data === void 0 ? void 0 : data.sales_target));
    return (_jsx(_Fragment, { children: _jsxs(Card, __assign({ color: "white", bg: "primary.500", borderRadius: "0 0 15px 15px" }, { children: [isLoading && (_jsx(Flex, __assign({ justifyContent: "center", alignItems: "center", h: 150 }, { children: _jsx(Spinner, { color: "white", opacity: 0.4 }, void 0) }), void 0)), showDashboardStats ? (_jsxs(_Fragment, { children: [_jsx(CardBody, __assign({ display: "none" }, { children: _jsx(Flex, __assign({ justifyContent: "center" }, { children: _jsxs(Stat, __assign({ flex: "inherit", display: "block", cursor: "pointer", onClick: function () { return router.go('statement'); } }, { children: [_jsxs(StatLabel, __assign({ opacity: 0.7, textTransform: "uppercase" }, { children: ["My ", distributor === null || distributor === void 0 ? void 0 : distributor.name, " Balance"] }), void 0), _jsx(StatNumber, __assign({ fontFamily: "archivo", textAlign: "center" }, { children: formatCurrency(data === null || data === void 0 ? void 0 : data.statement_balance, {
                                                showCurrency: true,
                                            }) }), void 0)] }), void 0) }), void 0) }), void 0), _jsx(CardBody, __assign({ borderTop: "0px solid", borderColor: "#197AFE" }, { children: _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "center", gridGap: 3 }, { children: [hasUsedCredit ? (_jsxs(Stat, __assign({ cursor: "pointer", textAlign: "center", onClick: function () { return router.go('funding-requests'); } }, { children: [_jsx(StatLabel, __assign({ opacity: 0.7, textTransform: "uppercase" }, { children: "Obtainly Credit" }), void 0), _jsx(StatNumber, __assign({ fontFamily: "archivo" }, { children: formatCurrency(usedCredit, {
                                                    compact: true,
                                                    showCurrency: true,
                                                }) }), void 0), _jsx(StatHelpText, { children: _jsx(Flex, __assign({ gridGap: 1, alignItems: "center", justifyContent: "center" }, { children: _jsx(Badge, { children: "View Credits" }, void 0) }), void 0) }, void 0)] }), void 0)) : null, isOrderActivated ? (_jsxs(Stat, __assign({ cursor: "pointer", textAlign: "center", onClick: function () { return router.go('orders'); } }, { children: [_jsx(StatLabel, __assign({ opacity: 0.7, textTransform: "uppercase" }, { children: "My Orders" }), void 0), _jsx(StatNumber, __assign({ fontFamily: "archivo" }, { children: formatCurrency(data === null || data === void 0 ? void 0 : data.order_value_mtd, {
                                                    compact: true,
                                                    showCurrency: true,
                                                }) }), void 0), _jsx(StatHelpText, { children: _jsxs(Flex, __assign({ gridGap: 1, alignItems: "center", justifyContent: "center" }, { children: [_jsx(Badge, __assign({ fontFamily: "mono", bg: "#348AFF", color: "white" }, { children: data === null || data === void 0 ? void 0 : data.order_count_mtd }), void 0), _jsx(Text, __assign({ opacity: 0.7 }, { children: pluralize('order', data === null || data === void 0 ? void 0 : data.order_count_mtd) }), void 0)] }), void 0) }, void 0)] }), void 0)) : null, (data === null || data === void 0 ? void 0 : data.sales_target) ? (_jsxs(Stat, __assign({ textAlign: "center" }, { children: [_jsx(StatLabel, __assign({ opacity: 0.7, textTransform: "uppercase" }, { children: "My Target" }), void 0), _jsx(StatNumber, __assign({ fontFamily: "archivo" }, { children: formatCurrency(data === null || data === void 0 ? void 0 : data.sales_target, {
                                                    compact: true,
                                                    showCurrency: true,
                                                }) }), void 0), _jsx(StatHelpText, { children: _jsxs(Flex, __assign({ gridGap: 1, alignItems: "center", justifyContent: "center" }, { children: [_jsxs(Badge, __assign({ colorScheme: (data === null || data === void 0 ? void 0 : data.achieved_pc) > 80
                                                                ? 'success'
                                                                : (data === null || data === void 0 ? void 0 : data.achieved_pc) > 50
                                                                    ? 'warning'
                                                                    : 'critical', bg: (data === null || data === void 0 ? void 0 : data.achieved_pc) > 80
                                                                ? 'success.100'
                                                                : (data === null || data === void 0 ? void 0 : data.achieved_pc) > 50
                                                                    ? 'warning.100'
                                                                    : 'critical.100', color: (data === null || data === void 0 ? void 0 : data.achieved_pc) > 80
                                                                ? 'success.600'
                                                                : (data === null || data === void 0 ? void 0 : data.achieved_pc) > 50
                                                                    ? 'warning.600'
                                                                    : 'critical.600', fontFamily: "mono" }, { children: [data === null || data === void 0 ? void 0 : data.achieved_pc, "%"] }), void 0), _jsx(Text, __assign({ opacity: 0.7 }, { children: "achieved" }), void 0)] }), void 0) }, void 0)] }), void 0)) : null] }), void 0) }), void 0)] }, void 0)) : null] }), void 0) }, void 0));
};
