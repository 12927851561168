var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Flex } from '@chakra-ui/layout';
import { fromUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, ContentLoader, ListMaker, MonthYearPicker, Layout, } from 'src/core/components';
import { useNotifications } from 'src/core/hooks';
import { useAtom, authAtom, userAtom, distributorsAtom } from 'src/core/store';
import { LedgerLogItem } from 'src/ledger';
import { Button } from '@chakra-ui/react';
export var Payments = function () {
    var readNotifications = useNotifications().readNotifications;
    var _a = useState('all'), filter = _a[0], setFilter = _a[1];
    React.useEffect(function () {
        return function () { return readNotifications('payments'); };
    }, []); // eslint-disable-line
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var _b = useAtom(authAtom), activeDate = _b[0].activeDate, setAuth = _b[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(activeDate);
        return {
            _id: user === null || user === void 0 ? void 0 : user._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }, [activeDate, distributor, user]);
    var _c = useQueryModel('merchant.statement', payload), data = _c.data, isLoading = _c.isLoading;
    var transactions = React.useMemo(function () {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.all) === null || _a === void 0 ? void 0 : _a.list) || [];
    }, [data]);
    var filteredTransactions = React.useMemo(function () {
        var _transactions = transactions.reduce(function (output, currentItem) {
            // drop any unwanted tag from this point
            if (['signup'].includes(currentItem.tag)) {
                return output;
            }
            if (filter === 'all') {
                output.push(currentItem);
                return output;
            }
            if (currentItem.tag !== 'transfer') {
                output.push(currentItem);
                return output;
            }
            return output;
        }, []);
        return _transactions.sort(function (a, b) {
            return Number(a.created || 0) > Number(b.created || 0) ? -1 : 1;
        });
    }, [filter, transactions]);
    return (_jsxs(Layout, __assign({ headerProps: { title: 'Payment History' } }, { children: [_jsxs(Flex, __assign({ mt: 3, pt: "4px", alignItems: "center", justifyContent: "space-between", mb: 3 }, { children: [_jsx(MonthYearPicker, { value: activeDate, onChange: function (newDate) {
                            setAuth(function (state) { return (__assign(__assign({}, state), { activeDate: newDate })); });
                        } }, void 0), _jsxs(Flex, __assign({ alignItems: "center", gap: "0.75rem" }, { children: [_jsx(Button, __assign({ size: "sm", onClick: function () { return setFilter('all'); }, colorScheme: filter === 'all' ? 'primary' : 'grey', color: filter === 'all' ? 'white' : 'black', _hover: { bg: filter === 'all' ? 'primary.500' : 'grey.500' } }, { children: "All" }), void 0), _jsx(Button, __assign({ size: "sm", onClick: function () { return setFilter('credit'); }, colorScheme: filter === 'credit' ? 'primary' : 'grey', color: filter === 'credit' ? 'white' : 'black', _hover: { bg: filter === 'credit' ? 'primary.500' : 'grey.500' } }, { children: "Credit" }), void 0)] }), void 0)] }), void 0), _jsx(Card, { children: _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.length), errorSummary: "No payments found" }, void 0), !isLoading && (filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.length) ? (_jsx(ListMaker, { data: filteredTransactions, component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }), void 0) }, void 0)] }), void 0));
};
