var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, VStack } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { useCreditLimit, useMessageConfig } from '@obtainly/hooks';
import { ContentLoader, ErrorBox } from '@obtainly/ui/web-shared';
import { useMemo } from 'react';
import { Layout } from 'src/core/components';
import { distributorsAtom, fundingAtom, profileAtom, useAtom, userAtom, } from 'src/core/store';
import { LaunchBanner } from 'src/dashboard/components/LaunchBanner';
import { FundingAgreementWrapper, FundingRequestForm, } from 'src/funding-request/components';
import { MerchantProfileCTA } from 'src/merchant';
function renderStep(step) {
    switch (step) {
        case 1:
            return _jsx(FundingRequestForm, {}, void 0);
        case 2:
            return _jsx(FundingAgreementWrapper, {}, void 0);
        default:
            return _jsx(FundingRequestForm, {}, void 0);
    }
}
export var NewFundingRequest = function () {
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorsAtom)[0].active;
    var step = useAtom(fundingAtom)[0].step;
    var profileState = useAtom(profileAtom)[0];
    var isLoadingProfile = profileState.isLoading, isComplete = profileState.isComplete;
    var isForceMigrateActivated = useMessageConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id).isForceMigrateActivated;
    var _a = useCreditLimit("".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id), user === null || user === void 0 ? void 0 : user._id), loadingCreditLimit = _a.isLoading, availableCreditLimit = _a.availableCreditLimit;
    var stopNewFundingRequest = useMemo(function () {
        if (!availableCreditLimit || availableCreditLimit <= 0) {
            return true;
        }
        return false;
    }, [availableCreditLimit]);
    var isLoading = isLoadingProfile || loadingCreditLimit;
    return (_jsxs(Layout, __assign({ headerProps: {
            title: 'New Credit Request',
        } }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), isForceMigrateActivated ? (_jsx(LaunchBanner, {}, void 0)) : (_jsx(_Fragment, { children: !isLoading &&
                    (stopNewFundingRequest ? (_jsx(Box, __assign({ pt: "30px" }, { children: _jsx(ErrorBox, { title: "Service unavailable", message: "Sorry, you cannot request for credit at the moment." }, void 0) }), void 0)) : isComplete ? (_jsx(Grid, __assign({ my: 3, templateColumns: ['1fr', '1fr'], gridGap: 5 }, { children: _jsx(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: renderStep(step) }), void 0) }), void 0)) : (_jsx(MerchantProfileCTA, {}, void 0))) }, void 0))] }), void 0));
};
