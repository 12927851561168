import { Promotions } from './pages/Promotions';
export var promotionRoutes = [
    {
        id: 'promotions',
        path: '/promotions',
        component: Promotions,
        requiresAuth: true,
        exact: true,
    },
];
export var promotionPermissions = [];
