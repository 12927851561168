var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { everyone, Role } from '@obtainly/bootstrap';
import { complaintPermissions } from 'src/complaint';
import { fundingRequestPermissions } from 'src/funding-request';
import { merchantPermissions } from 'src/merchant';
import { notificationPermissions } from 'src/notifications';
import { orderPermissions } from 'src/order';
import { productPermissions } from 'src/product';
export var permissions = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], complaintPermissions, true), merchantPermissions, true), notificationPermissions, true), orderPermissions, true), productPermissions, true), fundingRequestPermissions, true), [
    { id: 'view_reports', roles: [Role.DistributorStaff] },
    { id: 'view_settings', roles: __spreadArray([], everyone, true) },
], false);
