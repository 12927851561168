var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Heading } from '@chakra-ui/layout';
import { Redirect } from 'react-router';
import { AuthLayout } from 'src/core/components';
import { LoginForm } from '../components';
import { useAtom, authAtom } from 'src/core/store';
export var Login = function () {
    var token = useAtom(authAtom)[0].token;
    var isAuthenticated = !!token;
    if (isAuthenticated) {
        return _jsx(Redirect, { to: "/" }, void 0);
    }
    return (_jsx(AuthLayout, { children: _jsxs(Box, __assign({ mt: "25px", mb: "50px", bg: "white", mx: "auto", maxW: "500px", borderRadius: "8px", p: ['30px', '30px', '40px'], boxShadow: "1px 4px 10px 0px rgba(23, 28, 52, 0.06)" }, { children: [_jsx(Heading, __assign({ as: "h3", fontSize: "2xl", fontWeight: "semibold" }, { children: "Sign in to your account" }), void 0), _jsx(Box, __assign({ mt: 5 }, { children: _jsx(LoginForm, {}, void 0) }), void 0)] }), void 0) }, void 0));
};
